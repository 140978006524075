<template>
  <v-container fluid>
    <!-- <div class="get-data" v-on:click="extractSubInfo()">extract sub info</div> -->

    <!-- <div class="get-data" v-on:click="getTopTen()">hola</div>

    <vue-json-to-csv class="get-data" :json-data="topTen">
      Download Data
    </vue-json-to-csv> -->
    <!-- <div class="get-data" v-on:click="getPresetsInfo()">hola</div>

    <vue-json-to-csv class="get-data" :json-data="presetsInfo">
      Download Data
    </vue-json-to-csv> -->
    <!-- <div class="get-data" v-on:click="getJanuaryPosts()">hola</div>

    <vue-json-to-csv class="get-data" :json-data="postsJson">
      Download Data
    </vue-json-to-csv> -->

    <!-- <div class="get-data" v-on:click="getWorkoutsData()">hola</div>

    <vue-json-to-csv class="get-data" :json-data="workoutsDataJson">
      Download Data
    </vue-json-to-csv> -->

    <!-- <div class="get-data" v-on:click="getFreeUsersData()">hola</div>

    <vue-json-to-csv class="get-data" :json-data="freeUsersDataJson">
      Download Data
    </vue-json-to-csv> -->

    <!-- <div class="get-data" v-on:click="getProgramUsers()">hola</div>

      <vue-json-to-csv class="get-data" :json-data="programUsers">
        Download Data
      </vue-json-to-csv> -->

    <!-- <div class="get-data" v-on:click="getExercisesData()">get</div>

    <vue-json-to-csv class="get-data" :json-data="exercisesData">
      Download Data
    </vue-json-to-csv> -->

    <!-- <div class="get-data" v-on:click="fixPosts()">Fix posts</div> -->

    <!-- <vue-json-to-csv class="get-data" :json-data="presetsInfo">
      Presets
    </vue-json-to-csv>

    <vue-json-to-csv class="get-data" :json-data="oldExercisesInfo">
      Old Exercises
    </vue-json-to-csv>

    <vue-json-to-csv class="get-data" :json-data="newExercisesInfo">
      New Exercises
    </vue-json-to-csv>

    <vue-json-to-csv class="get-data" :json-data="livesInfo">
      Lives
    </vue-json-to-csv>

    <div class="get-data" v-on:click="getWorkoutsInfo()">get</div> -->

    <!-- <div class="get-data" v-on:click="getUserResults()">hola</div>

    <vue-json-to-csv class="get-data" :json-data="userResults">
      Download Data
    </vue-json-to-csv> -->

    <!-- <div class="get-data" v-on:click="getUsersData()">get</div>

    <vue-json-to-csv class="get-data" :json-data="usersData">
      Download Data
    </vue-json-to-csv> -->

    <v-row justify="start" class="px-10">
      <v-col cols="12" sm="12" md="1">
        <p class="text-h6 text--secondary font-weight-bold">Dashboard</p>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-btn
          v-if="getAdmin.isDeveloper"
          color="primary"
          @click="openDialogSubscriptionsStats()"
        >
          Get Subscriptions Stats
        </v-btn>
        <!-- <v-btn v-if="getAdmin.isDeveloper" color="primary" @click="$store.dispatch('GetUsersForUpdateTopics')">
            UPDATE USERS
          </v-btn> -->
      </v-col>
      <v-col cols="12" sm="12" md="9">
        <div v-if="getAdmin.isDeveloper" class="d-flex align-center">
          <p class="ml-3 mb-0">
            <v-icon color="primary" class="mr-1" size="18">mdi-account</v-icon
            >Total users:
            <span
              class="font-weight-bold primary--text"
              v-if="!loadingDateRangeUsers"
              >{{ totalUsersCounterByRange }}</span
            ><v-progress-circular
              indeterminate
              color="primary"
              size="20"
              v-else
            ></v-progress-circular>
          </p>
          <date-range-picker
            v-model="dateRangeUsers"
            class="range-picker-protrain ml-3"
            :date-range="dateRangeUsers"
            :single-date-picker="'range'"
            :auto-apply="true"
            ref="picker"
            @update="searchUsersByDateRange()"
          >
            <template #input="picker" style="min-width: 350px">
              <v-icon color="primary">mdi-account</v-icon>
              {{ picker ? dateRangeTextUsers : "" }}
            </template>
          </date-range-picker>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <!-- <v-col cols="12" sm="12" md="5">
          <v-card class="mx-auto pa-8">
            <canvas id="usersByAge"></canvas>
          </v-card>
        </v-col> -->
      <v-col cols="12" sm="12" md="5">
        <v-card class="mx-auto pa-8">
          <canvas id="usersByGender"></canvas>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="5">
        <v-card class="mx-auto pa-8">
          <canvas id="usersByLevel"></canvas>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="5">
        <v-card class="mx-auto pa-8">
          <canvas id="usersByChallenge"></canvas>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <date-range-picker
          v-model="dateRangeChart"
          class="range-picker-protrain"
          :date-range="dateRangeChart"
          :single-date-picker="'range'"
          :auto-apply="true"
          ref="pickerChart"
          @update="searchByDateRange()"
        >
          <template #input="picker" style="min-width: 350px">
            <v-icon color="primary">mdi-eye</v-icon>
            {{ picker ? dateRangeText : "" }}
          </template>
        </date-range-picker>
      </v-col>
      <v-col cols="12" sm="12" md="5">
        <v-card class="mx-auto pa-8">
          <p class="ml-3">
            <v-icon color="primary" class="mr-1" size="18">mdi-eye</v-icon
            >ProTrain Total dates filter:
            <span class="font-weight-bold primary--text" v-if="!loading">{{
              totalDatesFilter
            }}</span
            ><v-progress-circular
              indeterminate
              color="primary"
              size="20"
              v-else
            ></v-progress-circular>
          </p>
          <canvas id="proTrainDateRangeReproductions"></canvas>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="5">
        <v-card class="mx-auto pa-8">
          <p class="ml-3">
            <v-icon color="primary" class="mr-1" size="18">mdi-video</v-icon
            >ProTrain Total reproductions:
            <span class="font-weight-bold primary--text">{{
              totalReproductions
            }}</span>
          </p>
          <canvas id="proTrainTotalReproductions"></canvas>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color">{{
      snackbar.text
    }}</v-snackbar>
    <v-dialog
      v-model="dialogSubscriptionsStats.show"
      max-width="500"
      persistent
    >
      <v-card class="pa-6">
        <template v-if="dialogSubscriptionsStats.loading">
          <div>
            <v-card-text class="d-flex flex-column align-center">
              <p class="secondary--text text-center pb-1">
                Obtaining data, this process will take a few minutes...
              </p>
              <v-progress-circular
                indeterminate
                color="primary"
                size="70"
                :width="8"
              ></v-progress-circular>
            </v-card-text>
          </div>
        </template>
        <template v-else>
          <div>
            <h3 class="primary--text">
              Total Users:
              <span class="secondary--text">{{
                dialogSubscriptionsStats.data.totalUsers
              }}</span>
            </h3>
            <h3 class="primary--text">
              Total Premium:
              <span class="secondary--text">{{
                dialogSubscriptionsStats.data.premium
              }}</span>
            </h3>
            <h3 class="primary--text">
              Total GrandFathered:
              <span class="secondary--text">{{
                dialogSubscriptionsStats.data.grandFathered
              }}</span>
            </h3>
            <h3 class="primary--text">
              Total Free:
              <span class="secondary--text">{{
                dialogSubscriptionsStats.data.free
              }}</span>
            </h3>
            <v-btn color="primary" @click="closeDialogSubscriptionsStats()">
              Close
            </v-btn>
          </div>
        </template>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import Chart from "chart.js";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  name: "Dashboard",
  components: { DateRangePicker },
  data: () => ({
    postsJson: [],
    userResults: [],
    presetsInfo: [],
    topTen: [],
    oldExercisesInfo: [],
    newExercisesInfo: [],
    livesInfo: [],
    workoutsDataJson: [],
    exercisesData: [],
    freeUsersDataJson: [],
    programUsers: [],
    usersData: [],
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    dateRangeChart: {
      startDate: null,
      endDate: null,
    },
    dateRangePicker: {
      disabled: false,
    },
    loading: true,
    dateRangeChartConfig: null,
    dialogSubscriptionsStats: {
      show: false,
      loading: false,
      data: {},
    },
    dateRangePickerUsers: {
      disabled: false,
      show: false,
      range: [],
    },
    dateRangeUsers: {
      startDate: null,
      endDate: null,
    },
    loadingDateRangeUsers: true,
    totalUsersCounterByRange: 0,
  }),
  created() {
    let vm = this;
    vm.$emit("showOverlay", true);
    vm.dateRangeChart = vm.$store.state.ProTrainersModule.dateRangeChart;
    vm.generateChartsData()
      .then(async () => {
        if (vm.getAdmin.isDeveloper) {
          vm.dateRangeUsers = vm.$store.state.AuthModule.dateRangeUsers;
          await vm.searchUsersByDateRange();
        }
        vm.$emit("showOverlay", false);
      })
      .catch((err) => {
        vm.snackbar.text = "Error getting counters";
        vm.snackbar.color = "red";
        console.error(err);
        vm.$emit("showOverlay", false);
        vm.snackbar.snackbar = true;
      });
  },
  methods: {
    async extractSubInfo() {
      let vm = this;
      vm.$store
        .dispatch("extractSubInfo")
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getTopTen() {
      let vm = this;
      vm.$store
        .dispatch("getTopTen")
        .then((res) => {
          console.log(res);
          this.topTen = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getPresetsInfo() {
      let vm = this;
      vm.$store
        .dispatch("getPresetsInfo")
        .then((res) => {
          console.log(res);
          this.presetsInfo = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getUsersData() {
      let vm = this;
      vm.$store
        .dispatch("getUsersData")
        .then((res) => {
          console.log(res);
          this.usersData = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getJanuaryPosts() {
      let vm = this;
      vm.$store
        .dispatch("getJanuaryPosts")
        .then((res) => {
          console.log(res);
          this.postsJson = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getExercisesData() {
      let vm = this;
      vm.$store
        .dispatch("getExercisesData")
        .then((res) => {
          console.log(res);
          this.exercisesData = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getUserResults() {
      let vm = this;
      vm.$store
        .dispatch("getUserResults", "CX4dJJnbJxZQM8ar6XCFrQ9zKAr1")
        .then((res) => {
          console.log(res);
          this.userResults = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getWorkoutsInfo() {
      let vm = this;
      vm.$store
        .dispatch("getWorkoutsInfo")
        .then((res) => {
          console.log(res);
          this.presetsInfo = res.presetsList;
          this.oldExercisesInfo = res.oldExercisesList;
          this.newExercisesInfo = res.newExercisesList;
          this.livesInfo = res.livesList;
          // this.workoutsDataJson = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getWorkoutsData() {
      let vm = this;
      vm.$store
        .dispatch("getWorkoutsData")
        .then((res) => {
          console.log(res);
          this.workoutsDataJson = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getFreeUsersData() {
      let vm = this;
      vm.$store
        .dispatch("getFreeUsersData")
        .then((res) => {
          console.log(res);
          this.freeUsersDataJson = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getProgramUsers() {
      let vm = this;
      vm.$store
        .dispatch("getProgramUsers")
        .then((res) => {
          console.log(res);
          this.programUsers = res.rows;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fixPosts() {
      let vm = this;
      vm.$store
        .dispatch("fixPosts")
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getDataTest() {
      let vm = this;
      const res = await vm.$store.dispatch("GetDataTest");
    },
    generateChartsData() {
      let vm = this;
      return new Promise((resolve, reject) => {
        vm.usersByLevelChart();
        vm.usersByGenderChart();
        vm.usersByChallegeChart();
        vm.getProTrainData();
        resolve();
      });
    },
    async updateLives() {
      const vm = this;
      await vm.$store.dispatch("UpdateLivesOrientation");
    },
    async openDialogSubscriptionsStats() {
      const vm = this;
      try {
        vm.dialogSubscriptionsStats.show = true;
        vm.dialogSubscriptionsStats.loading = true;
        const res = await vm.$store.dispatch("GetUserSubcriptionsStats");
        vm.dialogSubscriptionsStats.data = res;
        console.log("res", res);
        vm.dialogSubscriptionsStats.loading = false;
      } catch (error) {
        vm.dialogSubscriptionsStats.show = false;
        vm.dialogSubscriptionsStats.loading = false;
        vm.snackbar.text = "Error getting stats";
        vm.snackbar.color = "red";
        console.error(error);
        vm.snackbar.snackbar = true;
      }
    },
    closeDialogSubscriptionsStats() {
      const vm = this;
      vm.dialogSubscriptionsStats = {
        show: false,
        loading: false,
        data: {},
      };
    },
    openDialogUsersCreatedInRangeDate() {
      // const vm = this
      let curr = new Date();
      let week = [];

      for (let i = 1; i <= 7; i++) {
        let first = curr.getDate() - curr.getDay() + i;
        let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
        week.push(day);
      }

      console.log("curr", week);
    },
    async searchUsersByDateRange() {
      let vm = this;
      try {
        vm.dateRangePickerUsers.disabled = true;
        vm.loadingDateRangeUsers = true;

        const datesChanged = {
          startDate: new Date(vm.dateRangeUsers.startDate.setHours(0, 0, 0, 0)),
          endDate: new Date(
            vm.dateRangeUsers.endDate.setHours(23, 59, 59, 999)
          ),
        };

        this.$store.commit("SET_DATE_RANGE_USERS", datesChanged);

        const dateRangeQuery = {
          startDate: vm.getDateRangesUsers.startDate,
          endDate: vm.getDateRangesUsers.endDate,
        };

        const res = await vm.$store.dispatch(
          "GetUsersByDateRange",
          dateRangeQuery
        );
        vm.totalUsersCounterByRange = res;
        vm.dateRangePickerUsers.disabled = false;
        vm.loadingDateRangeUsers = false;
      } catch (error) {
        vm.dateRangePickerUsers.disabled = false;
        console.error(error);
        vm.loadingDateRangeUsers = false;
      }
    },
    // usersByAgeChart() {
    //   let ctx = document.getElementById("usersByAge");
    //   let usrByAge = new Chart(ctx, {
    //     type: "bar",
    //     data: {
    //       labels: [
    //         "18-20",
    //         "21-25",
    //         "26-30",
    //         "31-35",
    //         "36-40",
    //         "41-45",
    //         "46-50",
    //         "+50",
    //       ],
    //       datasets: [
    //         {
    //           barThickness: 10,
    //           minBarLength: 10,
    //           label: "Users by age",
    //           data: [100, 200, 150, 300, 100, 130, 90, 65, 50],
    //           backgroundColor: "rgba(236, 92, 0, 1)",
    //           borderColor: "rgba(236, 92, 0, 1)",
    //           borderWidth: 1,
    //         },
    //       ],
    //     },
    //   });
    // },
    usersByLevelChart() {
      let vm = this;
      vm.$store
        .dispatch("GetCounterAnalitycs", "fitnessLevel")
        .then((res) => {
          let ctx = document.getElementById("usersByLevel");
          let usrByLevel = new Chart(ctx, {
            type: "bar",
            data: {
              labels: ["Elite", "Serious", "Casual", "Starter"],
              datasets: [
                {
                  barThickness: 10,
                  minBarLength: 10,
                  label: "Users by level",
                  data: [
                    this.getCounterByLevel.elite,
                    this.getCounterByLevel.serious,
                    this.getCounterByLevel.casual,
                    this.getCounterByLevel.starter,
                  ],
                  backgroundColor: "rgba(236, 92, 0, 1)",
                  borderColor: "rgba(236, 92, 0, 1)",
                  borderWidth: 1,
                },
              ],
            },
          });
        })
        .catch((err) => {
          vm.snackbar.text = "Error getting counters";
          vm.snackbar.color = "red";
          console.error(err);
          vm.snackbar.snackbar = true;
        });
    },
    usersByGenderChart() {
      let vm = this;
      vm.$store
        .dispatch("GetCounterAnalitycs", "gender")
        .then((res) => {
          let ctx = document.getElementById("usersByGender");
          let usrByGender = new Chart(ctx, {
            type: "pie",
            data: {
              labels: ["Male Users", "Female Users"],
              datasets: [
                {
                  label: "Users by gender",
                  data: [
                    vm.getCounterByGender.male,
                    vm.getCounterByGender.female,
                  ],
                  backgroundColor: [
                    "rgba(236, 92, 0, 1)",
                    "rgba(228, 131, 69, 1)",
                  ],
                  borderColor: ["rgba(236, 92, 0, 1)", "rgba(228, 131, 69, 1)"],
                },
              ],
            },
          });
        })
        .catch((err) => {
          vm.snackbar.text = "Error getting counters";
          vm.snackbar.color = "red";
          console.error(err);
          vm.snackbar.snackbar = true;
        });
    },
    usersByChallegeChart() {
      let vm = this;
      vm.$store
        .dispatch("GetCounterAnalitycs", "challenge")
        .then((res) => {
          let ctx = document.getElementById("usersByChallenge");
          let usrByChallenge = new Chart(ctx, {
            type: "bar",
            data: {
              labels: ["Burn", "Tone", "Build"],
              datasets: [
                {
                  barThickness: 10,
                  minBarLength: 10,
                  label: "Users by challenge",
                  data: [
                    this.getCounterByChallenge.burn,
                    this.getCounterByChallenge.tone,
                    this.getCounterByChallenge.build,
                  ],
                  backgroundColor: "rgba(236, 92, 0, 1)",
                  borderColor: "rgba(236, 92, 0, 1)",
                  borderWidth: 1,
                },
              ],
            },
          });
        })
        .catch((err) => {
          vm.snackbar.text = "Error getting counters";
          vm.snackbar.color = "red";
          console.error(err);
          vm.snackbar.snackbar = true;
        });
    },
    proTrainTotalReproductionsChart() {
      let vm = this;
      let ctx = document.getElementById("proTrainTotalReproductions");
      let palleteColor = vm.getColorsPallete(vm.getProTrainersList);
      let proTrainTotalReproductions = new Chart(ctx, {
        type: "pie",
        options: {
          legend: {
            display: false,
          },
        },
        data: {
          labels: vm.getProTrainersList.map((proTrain) => proTrain.name),
          datasets: [
            {
              label: "PRotrain",
              data: vm.getProTrainersList.map(
                (proTrain) => proTrain.counterReproductions
              ),
              backgroundColor: palleteColor,
              borderColor: palleteColor,
            },
          ],
        },
      });
    },
    proTrainTotalReproductionsChart() {
      let vm = this;
      let ctx = document.getElementById("proTrainTotalReproductions");
      let palleteColor = vm.getColorsPallete(vm.getProTrainersList);
      let proTrainTotalReproductions = new Chart(ctx, {
        type: "pie",
        options: {
          legend: {
            display: false,
          },
        },
        data: {
          labels: vm.getProTrainersList.map((proTrain) => proTrain.name),
          datasets: [
            {
              label: "PRotrain",
              data: vm.getProTrainersList.map(
                (proTrain) => proTrain.counterReproductions
              ),
              backgroundColor: palleteColor,
              borderColor: palleteColor,
            },
          ],
        },
      });
    },
    proTrainDateRangeReproductionsChart() {
      let vm = this;
      let ctx = document.getElementById("proTrainDateRangeReproductions");
      let palleteColor = vm.getColorsPallete(vm.getProTrainersList);
      let dateRangeReproductions = new Chart(ctx, {
        type: "pie",
        options: {
          legend: {
            display: false,
          },
        },
        data: {
          labels: vm.getProTrainersList.map((proTrain) => proTrain.name),
          datasets: [
            {
              label: "PRotrain",
              data: vm.getProTrainersList.map(
                (proTrain) => proTrain.takedWorkouts.length
              ),
              backgroundColor: palleteColor,
              borderColor: palleteColor,
            },
          ],
        },
      });
      vm.dateRangeChartConfig = dateRangeReproductions;
    },
    updateProTrainDateRangeReproductionsChart() {
      let vm = this;
      vm.dateRangeChartConfig.data.labels = vm.getProTrainersList.map(
        (proTrain) => proTrain.name
      );
      vm.dateRangeChartConfig.data.datasets[0].data = vm.getProTrainersList.map(
        (proTrain) => proTrain.takedWorkouts.length
      );
      vm.dateRangeChartConfig.update();
    },
    getProTrainData() {
      let vm = this;
      vm.$store
        .dispatch("GetProTrainersCounterReproductor")
        .then((resCounter) => {
          vm.$store
            .dispatch("GetProTrainersList")
            .then((res) => {
              vm.proTrainTotalReproductionsChart();
              vm.searchByDateRange();
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((errCounter) => {
          console.error(errCounter);
        });
    },
    searchByDateRange() {
      let vm = this;
      vm.loading = true;
      vm.dateRangePicker.disabled = true;

      const datesChanged = {
        startDate: new Date(vm.dateRangeChart.startDate.setHours(0, 0, 0, 0)),
        endDate: new Date(vm.dateRangeChart.endDate.setHours(23, 59, 59, 999)),
      };

      this.$store.commit("SET_DATE_RANGE_CHART", datesChanged);

      const dateRangeQuery = {
        startDate: vm.getProTrainDateRangesChart.startDate,
        endDate: vm.getProTrainDateRangesChart.endDate,
      };
      // console.log('searchByDateRange', dateRange);

      vm.$store
        .dispatch("GetProTrainersTakenInRangeDate", dateRangeQuery)
        .then((res) => {
          if (vm.dateRangeChartConfig === null) {
            vm.proTrainDateRangeReproductionsChart();
          } else {
            vm.updateProTrainDateRangeReproductionsChart();
          }
          vm.loading = false;
          vm.dateRangePicker.disabled = false;
        })
        .catch((err) => {
          vm.loading = false;
          vm.dateRangePicker.disabled = false;
          console.error(err);
        });
    },
    getColorsPallete(arrayBase) {
      let colors = [
        "rgba(236, 92, 0, 1)",
        "rgba(228, 131, 69, 1)",
        "rgba(255, 99, 0, 1)",
        "rgba(239, 156, 104, 1)",
      ];
      let counterColor = 0;
      let colorsSequence = [];
      for (let i = 0; i < arrayBase.length; i++) {
        colorsSequence.push(colors[counterColor]);
        if (counterColor === colors.length - 1) {
          counterColor = 0;
        } else {
          counterColor++;
        }
      }
      return colorsSequence;
    },
  },
  computed: {
    dateRangeText() {
      let startDate = moment(this.getProTrainDateRangesChart.startDate).format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.getProTrainDateRangesChart.endDate).format(
        "YYYY-MM-DD"
      );
      if (startDate !== endDate) {
        return `${startDate} to ${endDate}`;
      } else {
        return startDate;
      }
    },
    dateRangeTextUsers() {
      let startDate = moment(this.getDateRangesUsers.startDate).format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.getDateRangesUsers.endDate).format(
        "YYYY-MM-DD"
      );
      if (startDate !== endDate) {
        return `${startDate} to ${endDate}`;
      } else {
        return startDate;
      }
    },
    totalReproductions() {
      return Object.values(this.getProTrainersCounterReproductor).reduce(
        (counter, proTrain) => proTrain + counter,
        0
      );
    },
    totalDatesFilter() {
      let counterTotal = this.getProTrainersList.reduce(
        (counter, proTrain) => proTrain.takedWorkoutsCounter + counter,
        0
      );
      if (!Number.isNaN(counterTotal)) {
        return counterTotal;
      }
    },
    ...mapGetters([
      "getAdmin",
      "getCounterByGender",
      "getCounterByLevel",
      "getCounterByChallenge",
      "getProTrainersList",
      "getProTrainersCounterReproductor",
      "getProTrainDateRangesChart",
      "getDateRangesUsers",
    ]),
  },
};
</script>
<style scoped>
.vue-daterange-picker /deep/ .daterangepicker td.in-range {
  background-color: #ec5b014b !important;
}

.vue-daterange-picker /deep/ .daterangepicker td.active {
  background-color: #ec5b01 !important;
}

.vue-daterange-picker /deep/ .calendars-container {
  width: 0;
}

.vue-daterange-picker /deep/ .daterangepicker .ranges li.active {
  background-color: #ec5b01;
}

.vue-daterange-picker /deep/ .reportrange-text {
  transition: all 1s ease-in-out;
  background-color: #e1e1e1 !important;
  border-radius: 20px;
  border: 1px solid #8c8c8c;
}

.get-data {
  height: 64px;
  width: 64px;
  background-color: blue;
  border-radius: 32px;
  display: flex;
  justify-self: center;
  align-items: center;
  text-align: center;
  color: #fff;
}

.get-data:hover {
  cursor: pointer;
}
</style>