<template>
  <div class="d-flex justify-space-around">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="transparent" class="optionsbutton" v-bind="attrs" v-on="on"
          ><span class="elipsis">...</span></v-btn
        >
      </template>
      <v-list>
        <v-list-item
          v-if="onDuplicate !== undefined"
          @click="onDuplicate"
          key="edit"
        >
          <v-list-item-title
            ><v-icon class="mr-2">mdi-content-copy</v-icon
            >Duplicate</v-list-item-title
          >
        </v-list-item>
        <v-list-item v-if="onEdit !== undefined" @click="onEdit" key="edit">
          <v-list-item-title
            ><v-icon class="mr-2">mdi-pencil</v-icon>Edit</v-list-item-title
          >
        </v-list-item>
        <v-list-item
          v-if="onDelete !== undefined"
          @click="onDelete"
          key="delete"
        >
          <v-list-item-title
            ><v-icon class="mr-2">mdi-delete</v-icon>Delete</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "options-button",
  model: {},
  props: {
    onEdit: undefined,
    onDuplicate: undefined,
    onDelete: undefined,
    itemId: "",
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  watch: {},
};
</script>

<style scoped>
.elipsis {
  font-weight: bold !important;
  margin: 0 0 10px 0;
  font-size: 18px;
}

.optionsbutton{
  border-width: 0 !important;
  -webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
</style>
