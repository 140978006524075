<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="2">
        <p class="text-h6 text--secondary font-weight-bold">Achievements</p>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-btn
          text
          color="secondary"
          @click="changeRouteManagementAchievements('Types')"
        >
          <v-icon left dark color="primary"> mdi-format-list-bulleted </v-icon>
          Types
        </v-btn>
        <v-btn
          text
          color="secondary"
          @click="changeRouteManagementAchievements('Categories')"
        >
          <v-icon left dark color="primary"> mdi-format-list-bulleted </v-icon>
          Categories
        </v-btn>
        <v-btn
          text
          color="secondary"
          @click="changeRouteManagementAchievements('Intervals')"
        >
          <v-icon left dark color="primary"> mdi-format-list-bulleted </v-icon>
          Intervals
        </v-btn>
        <v-btn text color="secondary" @click="createAchievement()">
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          New achievement
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          label="Search for a achievements"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="getAchievementsList"
          item-key="id"
          fixed-header
          class="elevation-4"
          :page.sync="page"
          @update:page="onPageChange"
          :items-per-page.sync="itemsPerPage"
          @update:items-per-page="onItemsPerPageUpdate"
          :sortBy.sync="sortBy"
          @update:sortBy="onSortByChange"
        >
          <template v-slot:[`item.icon`]="{ item }">
            <img :src="item.icon.link" width="30" height="30" />
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <span
              class="d-block text-truncate mx-auto"
              style="max-width: 300px !important"
            >
              {{ item.description }}
            </span>
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <v-switch
              color="primary"
              class="center-switch"
              :input-value="item.enabled"
              readonly
              flat
            ></v-switch>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <options-button
              :onEdit="
                () => {
                  changeRouteAchievement(item);
                }
              "
              :onDuplicate="undefined"
              :onDelete="
                () => {
                  openDialogConfirm(item);
                }
              "
              :itemId="item.id"
              :key="item.id"
            />
            <!-- 
            <div class="dropdown">
              <span>...</span>
              <div class="dropdown-content">
                <v-btn
                  class="optionButton"
                  color="transparent"
                  @click="changeRouteAchievement(item)"
                >
                  <v-icon class="mr-2">mdi-pencil</v-icon>Edit
                </v-btn>
                <v-btn
                  class="optionButton"
                  color="transparent"
                  @click="openDialogConfirm(item)"
                >
                  <v-icon class="mr-2">mdi-delete</v-icon>Delete
                </v-btn>
              </div>
            </div> -->

            <!-- <v-btn icon color="primary" @click="changeRouteAchievement(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="red" class="mr-3" @click="openDialogConfirm(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn> -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
      <v-card class="text-center">
        <!-- <v-card-title class="headline">Use Google's location service?</v-card-title> -->
        <v-icon class="text-center ma-2" size="120" color="primary"
          >mdi-alert</v-icon
        >
        <v-card-text class="pa-4 text-center"
          >This action is irreversible and will delete all data about this
          Achievement. Including the icon, type and information</v-card-text
        >
        <v-card-text class="text-center">Are you sure?</v-card-text>

        <!-- <v-card-actions class="pa-0"> -->
        <!-- <v-spacer></v-spacer> -->

        <v-btn
          color="third"
          tile
          width="150"
          class="pa-0 text--secondary"
          @click="closeDialogConfirm()"
          >No</v-btn
        >

        <v-btn
          color="primary"
          tile
          width="150"
          class="pa-0"
          @click="openDialogReAuth('deleteAchievement', dialog.item)"
          >Yes</v-btn
        >
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.text }}</v-snackbar
    >
    <modal-reauth
      :dialogReAuth="dialogReAuth"
      @isSuccessReAuth="isSuccessReAuth"
    ></modal-reauth>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
import OptionsButton from "@/components/admin/OptionsButton.vue";
export default {
  name: "AchievementsList",
  components: { modalReauth, OptionsButton },
  data: () => ({
    search: "",
    loading: false,
    dialogReAuth: {
      show: false,
      action: "",
      item: {},
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    dialog: {
      show: false,
      item: {},
    },
    headers: [
      // {
      //   text: "Order Number",
      //   align: "center",
      //   value: "orderNumber",
      //   sortable: true,
      // },
      { text: "Icon", align: "center", value: "icon", sortable: true },
      { text: "Name", align: "center", value: "name", sortable: true },
      {
        text: "Description",
        align: "center",
        value: "description",
        sortable: true,
      },
      { text: "Enabled", align: "center", value: "enabled", sortable: true },
      { text: "Action", align: "center", value: "action", sortable: false },
    ],
    page: 0,
    itemsPerPage: 10,
    sortBy: "name.ENG",
  }),
  created() {
    let vm = this;
    vm.getAchievements();
    if (this.$route.params.previousRoute === "Achievements Edit") {
      const tableState = localStorage.getItem("achivementTableState");
      if (tableState) {
        const tableStateObj = JSON.parse(tableState);
        this.search = tableStateObj.query;
        this.itemsPerPage = tableStateObj.hitsPerPage;
        this.page = tableStateObj.page;
      }
    }
  },
  methods: {
    getAchievements() {
      let vm = this;
      vm.loading = true;
      vm.$store
        .dispatch("GetAchievementsList")
        .then((res) => {
          vm.loading = false;
        })
        .catch((err) => {
          console.error(err);
          vm.loading = false;
        });
    },
    createAchievement() {
      let vm = this;
      vm.$router.push({ name: "Achievements Create" }).catch((err) => {
        vm.snackbar.text =
          "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
    openDialogConfirm(item) {
      let vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
    },
    closeDialogConfirm() {
      let vm = this;
      vm.dialog.show = false;
      vm.dialog.item = "";
    },
    isSuccessReAuth(dialogReAuthResponse) {
      const vm = this;
      switch (dialogReAuthResponse.action) {
        case "deleteAchievement":
          vm.deleteAchievement(dialogReAuthResponse.item);
          break;
        default:
          break;
      }
    },
    openDialogReAuth(action, item) {
      let vm = this;
      vm.dialogReAuth.show = true;
      vm.dialogReAuth.action = action;
      vm.dialogReAuth.item = item;
      vm.closeDialogConfirm();
    },
    deleteAchievement(achievement) {
      const vm = this;
      vm.$emit("showOverlay", true);
      vm.$store
        .dispatch("DeleteAchievement", achievement)
        .then((res) => {
          vm.$store.dispatch("GenerateLog", {
            id: achievement.id,
            name: achievement.name,
            type: "deletedAchievement",
          });
          vm.getAchievements();
          vm.$emit("showOverlay", false);
          // vm.loading = false;
        })
        .catch((err) => {
          vm.$emit("showOverlay", false);
          console.error(err);
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error deleting Achievement",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        });
    },
    changeRouteAchievement(achievement) {
      let vm = this;
      vm.$router
        .push({ name: "Achievements Edit", params: achievement })
        .catch((err) => {
          vm.snackbar.text =
            "Sorry, you don't have permissions to enter this view.";
          vm.snackbar.color = "red darken-2";
          vm.snackbar.snackbar = true;
        });
    },
    async changeRouteManagementAchievements(typeManagement) {
      const vm = this;
      try {
        await vm.$router.push({ name: `Achievements ${typeManagement}` });
      } catch (error) {
        vm.snackbar.text =
          "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      }
    },
    onPageChange(page) {
      console.log(page);
    },
    onItemsPerPageUpdate(items) {
      console.log(items);
    },
    onSortByChange(sortBy, test) {
      console.log(sortBy, test);
    },
  },
  computed: {
    ...mapGetters(["getAchievementsList"]),
  },
  beforeRouteLeave(to, from, next) {
    console.log(to);
    if (to.name === "Achievements Edit") {
      const currentState = JSON.stringify({
        hitsPerPage: this.itemsPerPage,
        query: this.search,
        page: this.page,
      });
      localStorage.setItem("achivementTableState", currentState);
    } else {
      localStorage.removeItem("achivementTableState");
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "Achievements Edit") {
      to.params.previousRoute = from.name;
    }
    next();
  },
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>