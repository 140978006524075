<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="2">
        <p class="text-h6 text--secondary font-weight-bold">Tutorials</p>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-btn text color="secondary" @click="categoriesTutorials()">
          <v-icon left dark color="primary"> mdi-format-list-bulleted </v-icon>
          Categories Tutorials
        </v-btn>
        <v-btn text color="secondary" @click="createTutorial()">
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          New tutorial
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          label="Search for a tutorials"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="getTutorials"
          item-key="id"
          fixed-header
          class="elevation-4"
          :page.sync="page"
          @update:page="onPageChange"
          :items-per-page.sync="itemsPerPage"
          @update:items-per-page="onItemsPerPageUpdate"
          :sortBy.sync="sortBy"
          @update:sortBy="onSortByChange"
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-tooltip top color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <img
                  v-if="item.image.link"
                  v-bind="attrs"
                  v-on="on"
                  class="protrain-image-preview"
                  style="cursor: pointer"
                  @click="openDialogPreviewImage(item.image.link)"
                  :src="item.image.link"
                />
              </template>
              <span>Show Image</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.videoLink`]="{ item }">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="primary"
                  @click="openDialogPreviewVideo(item)"
                >
                  <v-icon>mdi-message-video</v-icon>
                </v-btn>
              </template>
              <span>Show Video</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <span
              class="d-block text-truncate mx-auto"
              style="max-width: 300px !important"
            >
              {{ item.description }}
            </span>
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <v-switch
              color="primary"
              class="center-switch"
              :input-value="item.enabled"
              readonly
              flat
            ></v-switch>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <options-button
              :onEdit="
                () => {
                  changeRouteTutorial(item);
                }
              "
              :onDuplicate="undefined"
              :onDelete="
                () => {
                  openDialogConfirm(item);
                }
              "
              :itemId="item.id"
              :key="item.id"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
      <v-card class="text-center">
        <!-- <v-card-title class="headline">Use Google's location service?</v-card-title> -->
        <v-icon class="text-center ma-2" size="120" color="primary"
          >mdi-alert</v-icon
        >
        <v-card-text class="pa-4 text-center"
          >This action is irreversible and will delete all data about this
          Tutorial. Including the video, image and information</v-card-text
        >
        <v-card-text class="text-center">Are you sure?</v-card-text>

        <!-- <v-card-actions class="pa-0"> -->
        <!-- <v-spacer></v-spacer> -->

        <v-btn
          color="third"
          tile
          width="150"
          class="pa-0 text--secondary"
          @click="closeDialogConfirm()"
          >No</v-btn
        >

        <v-btn
          color="primary"
          tile
          width="150"
          class="pa-0"
          @click="openDialogReAuth('deleteTutorial', dialog.item)"
          >Yes</v-btn
        >
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.text }}</v-snackbar
    >
    <modal-reauth
      :dialogReAuth="dialogReAuth"
      @isSuccessReAuth="isSuccessReAuth"
    ></modal-reauth>
    <v-dialog v-model="dialogPreviewImage.show" max-width="800">
      <v-card style="line-height: 0; !important;">
        <v-btn
          fab
          x-small
          color="red"
          style="position: absolute; top: 5px; left: 5px"
          @click="closeDialogImagePreview()"
        >
          <v-icon size="15" color="white">mdi-close</v-icon>
        </v-btn>
        <img :src="dialogPreviewImage.img" style="width: 100%; height: 100%" />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPreviewVideo.show"
      transition="dialog-bottom-transition"
      max-width="700"
    >
      <v-card class="text-center">
        <v-card-actions class="pa-0">
          <v-btn
            icon
            color="secondary"
            class="ml-3 mt-2"
            @click="closeDialogVideoPreview()"
          >
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <img
          src="@/assets/logo_x.png"
          style="vertical-align: middle"
          class="mx-2 d-inline"
          width="20"
        />
        <p class="font-weight-bold secondary--text d-inline">
          {{ dialogPreviewVideo.name }}
        </p>
        <v-card-text class="pb-8 mt-3">
          <!-- <iframe :src="dialogPreviewVideo.video"/> -->
          <iframe
            v-if="dialogPreviewVideo.video"
            ref="listProTrainVideoPreview"
            width="100%"
            height="350px"
            controls
            class="mx-auto"
            :src="dialogPreviewVideo.video ? dialogPreviewVideo.video : ''"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
import OptionsButton from "@/components/admin/OptionsButton.vue";
export default {
  name: "TutorialsList",
  components: { modalReauth,OptionsButton },
  data: () => ({
    search: "",
    loading: false,
    dialogReAuth: {
      show: false,
      action: "",
      item: {},
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    dialog: {
      show: false,
      item: {},
    },
    dialogPreviewImage: {
      show: false,
      video: "",
    },
    dialogPreviewVideo: {
      show: false,
      video: "",
      name: "",
    },
    headers: [
      // { text: "Img size", align: "center", value: "image.size", sortable: false },
      // {
      //   text: "Order Number",
      //   align: "center",
      //   value: "orderNumber",
      //   sortable: true,
      // },
      { text: "Image", align: "center", value: "image", sortable: false },
      { text: "Video", align: "center", value: "videoLink", sortable: false },
      { text: "Name", align: "center", value: "name.ENG", sortable: true },
      {
        text: "Description",
        align: "center",
        value: "description.ENG",
        sortable: true,
      },
      {
        text: "Category",
        align: "center",
        value: "category.title.ENG",
        sortable: true,
      },
      { text: "Enabled", align: "center", value: "enabled", sortable: true },
      { text: "Action", align: "center", value: "action", sortable: false },
    ],
    page: 0,
    itemsPerPage: 10,
    sortBy: "name.ENG",
  }),
  created() {
    let vm = this;
    vm.getTutorialsList();
    if (this.$route.params.previousRoute === "Tutorials Edit") {
      const tableState = localStorage.getItem("tutorialTableState");
      if (tableState) {
        const tableStateObj = JSON.parse(tableState);
        this.search = tableStateObj.query;
        this.itemsPerPage = tableStateObj.hitsPerPage;
        this.page = tableStateObj.page;
      }
    }
  },
  methods: {
    openDialogPreviewImage(img) {
      const vm = this;
      vm.dialogPreviewImage = {
        show: true,
        img: img ? img : "",
      };
    },
    closeDialogImagePreview() {
      const vm = this;
      vm.dialogPreviewImage.show = false;
      // vm.dialogPreviewImage = {
      //   show: false,
      //   img: ''
      // }
    },
    openDialogPreviewVideo(item) {
      const vm = this;
      console.log(item.previewVideo);

      vm.dialogPreviewVideo = {
        show: true,
        video: item.previewVideo ? item.previewVideo : "",
        name: item.name.ENG,
      };
    },
    closeDialogVideoPreview() {
      const vm = this;
      vm.dialogPreviewVideo = {
        show: false,
        video: "",
        name: "",
      };
    },
    getTutorialsList() {
      let vm = this;
      vm.loading = true;
      vm.$store
        .dispatch("GetTutorialsList")
        .then((res) => {
          vm.loading = false;
        })
        .catch((err) => {
          console.error(err);
          vm.loading = false;
        });
    },
    createTutorial() {
      let vm = this;
      vm.$router.push({ name: "Tutorials Create" }).catch((err) => {
        vm.snackbar.text =
          "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
    openDialogConfirm(item) {
      let vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
    },
    closeDialogConfirm() {
      let vm = this;
      vm.dialog.show = false;
      vm.dialog.item = "";
    },
    isSuccessReAuth(dialogReAuthResponse) {
      const vm = this;
      switch (dialogReAuthResponse.action) {
        case "deleteTutorial":
          vm.deleteTutorial(dialogReAuthResponse.item);
          break;
        default:
          break;
      }
    },
    openDialogReAuth(action, item) {
      let vm = this;
      vm.dialogReAuth.show = true;
      vm.dialogReAuth.action = action;
      vm.dialogReAuth.item = item;
      vm.closeDialogConfirm();
    },
    deleteTutorial(tutorial) {
      const vm = this;
      vm.$emit("showOverlay", true);
      vm.$store
        .dispatch("DeleteTutorial", tutorial)
        .then((res) => {
          vm.$store.dispatch("GenerateLog", {
            id: tutorial.id,
            title: tutorial.name.ENG,
            type: "deletedTutorial",
          });
          vm.$store.commit("DELETE_TUTORIAL_FROM_ARRAY", tutorial);
          vm.$emit("showOverlay", false);
          // vm.loading = false;
        })
        .catch((err) => {
          vm.$emit("showOverlay", false);
          console.error(err);
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error deleting Tutorial",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        });
    },
    changeRouteTutorial(tutorial) {
      let vm = this;
      vm.$router
        .push({ name: "Tutorials Edit", params: tutorial })
        .catch((err) => {
          vm.snackbar.text =
            "Sorry, you don't have permissions to enter this view.";
          vm.snackbar.color = "red darken-2";
          vm.snackbar.snackbar = true;
        });
    },
    categoriesTutorials() {
      const vm = this;
      vm.$router.push({ name: "Tutorials Categories" }).catch((err) => {
        vm.snackbar.text =
          "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
    onPageChange(page) {
      console.log(page);
    },
    onItemsPerPageUpdate(items) {
      console.log(items);
    },
    onSortByChange(sortBy, test) {
      console.log(sortBy, test);
    },
  },
  computed: {
    ...mapGetters(["getTutorials"]),
  },
  beforeRouteLeave(to, from, next) {
    console.log(to);
    if (to.name === "Tutorials Edit") {
      const currentState = JSON.stringify({
        hitsPerPage: this.itemsPerPage,
        query: this.search,
        page: this.page,
      });
      localStorage.setItem("tutorialTableState", currentState);
    } else {
      localStorage.removeItem("tutorialTableState");
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "Tutorials Edit") {
      to.params.previousRoute = from.name;
    }
    next();
  },
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}
.protrain-image-preview {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  vertical-align: middle;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>