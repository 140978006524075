<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-8">
      <!-- <v-col cols="12"> -->

      <!-- <v-btn color="primary" @click="generateUsersCSV()">Download CSV</v-btn>
        <a :href="csvData.href" :download="csvData.download" :disabled="!csvData.href">Download</a> -->
      <v-row justify="space-between">
        <v-col>
          <p class="text-h6 text--secondary font-weight-bold mb-0 px-4">Users</p>
        </v-col>
        <v-col style="display: flex; justify-content: end; align-items:center">
          <!-- <div v-if="freeUsers.length === 0 && loadingFreeUsers === false" class="csv" v-on:click="getFreeUsersData()">
            Generate Free Users CSV
          </div>
          <div v-if="freeUsers.length === 0 && loadingFreeUsers === true" class="csv disabled">
            Generating
          </div> -->

          <v-btn
            v-if="premiumUsers.length === 0"
            class="csv"
            :disabled="true"
            @click="getPremiumUsersData()"
          >
            Export
          </v-btn>
          <v-col cols="2">
            <select class="ais-HitsPerPage-select" style="margin:0" v-model="selectedFilter">
              <option
                class="ais-HitsPerPage-option"
                v-for="option in filterOptions"
                :key="option.label"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </v-col>
          <!-- <v-btn
            style="margin-right: 20px"
            v-if="premiumUsers.length === 0"
            class="csv"
            :disabled="loadingPremiumUsers"
            @click="getPremiumUsersData()"
          >
            {{ loadingPremiumUsers ? "Generating" : "Premium Users CSV" }}
          </v-btn>

          <vue-json-to-csv
            style="margin-right: 20px"
            v-if="premiumUsers.length > 0"
            class="csv"
            :json-data="premiumUsers"
          >
            Download
          </vue-json-to-csv>

          <v-btn
            v-if="freeUsers.length === 0"
            class="csv"
            :disabled="loadingFreeUsers"
            @click="getFreeUsersData()"
          >
            {{ loadingFreeUsers ? "Generating" : "Free Users CSV" }}
          </v-btn>

          <vue-json-to-csv
            v-if="freeUsers.length > 0"
            class="csv"
            :json-data="freeUsers"
          >
            Download
          </vue-json-to-csv> -->
        </v-col>
      </v-row>

      <v-col cols="12" sm="12" md="12">
        <ais-instant-search
          v-if="loadingStorageData === false"
          :initial-ui-state="initialUiState"
          :search-client="searchClient"
          index-name="usersIndex"
        >
          <ais-configure :filters.camel="algoliaFilters" />
          <!-- <ais-refinement-list
           attribute="isPremium"

            :transform-items="transformItems"
          /> -->
          <v-row class="pb-2">
            <v-col cols="10">
              <ais-search-box>
                <template v-slot="{ currentRefinement, refine }">
                  <!-- <input type="search" autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false"
                    required="required" aria-label="Search" placeholder="Search here..." class="ais-SearchBox-input"
                    :value="currentRefinement"
                    @input="refine($event.currentTarget.value); searchBox = $event.currentTarget.value" /> -->

                  <form
                    action=""
                    role="search"
                    novalidate="novalidate"
                    class="ais-SearchBox-form"
                  >
                    <input
                      type="search"
                      autocorrect="off"
                      autocapitalize="off"
                      autocomplete="off"
                      spellcheck="false"
                      required="required"
                      maxlength="512"
                      aria-label="Search"
                      placeholder="Search here…"
                      class="ais-SearchBox-input"
                      :value="currentRefinement"
                      @input="
                        refine($event.currentTarget.value);
                        searchBox = $event.currentTarget.value;
                      "
                    />
                    <button
                      type="submit"
                      title="Search"
                      class="ais-SearchBox-submit"
                    >
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 40 40"
                        class="ais-SearchBox-submitIcon"
                      >
                        <path
                          d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <button
                      type="reset"
                      title="Clear"
                      hidden="hidden"
                      class="ais-SearchBox-reset"
                    >
                      <svg
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        class="ais-SearchBox-resetIcon"
                      >
                        <path
                          d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <!---->
                  </form>
                </template>
              </ais-search-box>
            </v-col>
            <!-- <v-col cols="2" class="radioContainer">
              <input type="radio" id="one" value="" v-model="selectedFilter" />
              <label class="radioLabel" for="one">All</label>
              <input
                type="radio"
                id="two"
                value="isPremium:false"
                v-model="selectedFilter"
              />
              <label class="radioLabel" for="two">Free</label>
              <input
                type="radio"
                id="one"
                value="isPremium:true"
                v-model="selectedFilter"
              />
              <label class="radioLabel" for="one">Premium</label>
            </v-col> -->

            <v-col cols="2">
              <ais-hits-per-page :items="hitsPerPageOptions">
                <template v-slot="{ items, refine }">
                  <select
                    class="ais-HitsPerPage-select"
                    @input="
                      refine($event.currentTarget.value);
                      itemsPerPage = $event.currentTarget.value;
                    "
                  >
                    <option
                      :selected="option.default"
                      class="ais-HitsPerPage-option"
                      v-for="option in items"
                      :key="option.text"
                      :value="option.value"
                    >
                      {{ option.value }} results
                    </option>
                  </select>
                </template>
              </ais-hits-per-page>
            </v-col>
          </v-row>
          <ais-hits>
            <template v-slot="{ items }">
              <v-data-table
                :items="items"
                :headers="headers"
                :items-per-page="100"
                fixed-header
                hide-default-footer
                class="elevation-4"
              >
                <template v-slot:[`item.profile`]="{ item }">
                  <v-btn icon color="primary" @click="changeRouteUser(item)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.firstName`]="{ item }">
                  <span
                    @click="changeRouteUser(item)"
                    style="cursor: pointer"
                    >{{ item.firstName }}</span
                  >
                </template>
                <template v-slot:[`item.lastName`]="{ item }">
                  <span
                    @click="changeRouteUser(item)"
                    style="cursor: pointer"
                    >{{ item.lastName }}</span
                  >
                </template>
                <template v-slot:[`item.displayName`]="{ item }">
                  <span
                    @click="changeRouteUser(item)"
                    style="cursor: pointer"
                    >{{ item.displayName }}</span
                  >
                </template>
                <template v-slot:[`item.nickname`]="{ item }">
                  <span
                    @click="changeRouteUser(item)"
                    style="cursor: pointer"
                    >{{ item.nickname }}</span
                  >
                </template>
                <template v-slot:[`item.email`]="{ item }">
                  <span
                    @click="changeRouteUser(item)"
                    style="cursor: pointer"
                    >{{ item.email }}</span
                  >
                </template>
                <template v-slot:[`item.creationTime`]="{ item }">
                  <span>{{
                    item.creationTime
                      ? timestampToStringDate(item.creationTime)
                      : ""
                  }}</span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <!-- <template v-if="item.disabled === false"> -->
                  <v-btn
                    small
                    color="secondary"
                    class="mx-1 my-1"
                    width="150"
                    @click="openModal('restore', item.email, item)"
                    >Restore password</v-btn
                  >
                  <v-btn
                    small
                    color="secondary"
                    class="mx-1 my-1"
                    width="150"
                    @click="openModal('validate', item.email, item)"
                    >Validate email</v-btn
                  >
                  <v-btn
                    small
                    color="secondary"
                    class="mx-1 my-1"
                    width="150"
                    @click="changeRouteSendNotification(item)"
                    >Send Notification</v-btn
                  >
                </template>
                <template v-slot:footer="{}">
                  <div class="d-flex justify-center">
                    <ais-pagination class="pa-5">
                      <template
                        v-slot="{
                          currentRefinement,
                          nbPages,
                          pages,
                          isFirstPage,
                          isLastPage,
                          refine,
                          createURL,
                        }"
                      >
                        <ul class="ais-Pagination-list">
                          <li class="ais-Pagination-item" v-if="!isFirstPage">
                            <a
                              class="ais-Pagination-link"
                              :href="createURL(0)"
                              @click.prevent="
                                refine(0);
                                currentPage = 0;
                              "
                            >
                              ‹‹
                            </a>
                          </li>
                          <li class="ais-Pagination-item" v-if="!isFirstPage">
                            <a
                              class="ais-Pagination-link"
                              :href="createURL(currentRefinement - 1)"
                              @click.prevent="
                                refine(currentRefinement - 1);
                                currentPage = currentRefinement - 1;
                              "
                            >
                              ‹
                            </a>
                          </li>
                          <li
                            class="ais-Pagination-item"
                            v-for="page in pages"
                            :key="page"
                          >
                            <a
                              class="ais-Pagination-link"
                              :href="createURL(page)"
                              :style="{
                                fontWeight:
                                  page === currentRefinement ? 'bold' : '',
                              }"
                              @click.prevent="
                                refine(page);
                                currentPage = page;
                              "
                            >
                              {{ page + 1 }}
                            </a>
                          </li>
                          <li class="ais-Pagination-item" v-if="!isLastPage">
                            <a
                              class="ais-Pagination-link"
                              :href="createURL(currentRefinement + 1)"
                              @click.prevent="
                                refine(currentRefinement + 1);
                                currentPage = currentRefinement + 1;
                              "
                            >
                              ›
                            </a>
                          </li>
                          <li class="ais-Pagination-item" v-if="!isLastPage">
                            <a
                              class="ais-Pagination-link"
                              :href="createURL(nbPages)"
                              @click.prevent="
                                refine(nbPages);
                                currentPage = nbPages;
                              "
                            >
                              ››
                            </a>
                          </li>
                        </ul>
                      </template>
                      <!-- <template v-slot="{ currentRefinement, refine }">
                  <select class="ais-HitsPerPage-select"
                    @input="refine($event.currentTarget.value); itemsPerPage = $event.currentTarget.value">
                    <option :selected="option.default" class="ais-HitsPerPage-option" v-for="option in hitsPerPageOptions"
                      :key="option.text" :value="option.value">{{ option.value }} results</option>
                  </select>
                </template> -->
                    </ais-pagination>
                  </div>
                </template>
              </v-data-table>
            </template>
          </ais-hits>
        </ais-instant-search>
      </v-col>
      <!-- <v-col cols="12" sm="12" md="3">
         <v-chip
          v-if="!search.edit"
          class="ma-2"
          style="white-space: pre;"
          :color="search.values.firstName != '' ? 'primary' : 'secondary'"
          text-color="white"
        >First Name <span class="font-weight-black">{{search.values.firstName}}</span>
       </v-chip>
        <v-text-field
         v-if="search.edit"
          label="First Name"
          v-model="search.values.firstName"
          hide-details
          single-line
          outlined
          rounded
          filled
          dense
          :disabled="loading"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="3">
         <v-chip
          v-if="!search.edit"
          class="ma-2"
          style="white-space: pre;"
          :color="search.values.lastName != '' ? 'primary' : 'secondary'"
          text-color="white"
        >Last Name <span class="font-weight-black">{{search.values.lastName}}</span>
       </v-chip>
        <v-text-field
         v-if="search.edit"
          label="Last Name"
          v-model="search.values.lastName"
          hide-details
          single-line
          outlined
          rounded
          filled
          dense
          :disabled="loading"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4">
         <v-chip
          v-if="!search.edit"
          class="ma-2"
          style="white-space: pre;"
          :color="search.values.email != '' ? 'primary' : 'secondary'"
          text-color="white"
        >Email <span class="font-weight-black">{{search.values.email}}</span>
       </v-chip>
        <v-text-field
         v-if="search.edit"
          label="Email"
          v-model="search.values.email"
          hide-details
          single-line
          outlined
          rounded
          filled
          dense
          :disabled="loading"
        ></v-text-field>
        
      </v-col>
      <v-col cols="12" sm="12" md="1">
         <v-btn 
          :disabled="loading"
          class="mr-1"
          fab 
          elevation="0"
          x-small
          color="primary"
          @click="goSearch()"
          >
           <v-icon>mdi-magnify</v-icon>
         </v-btn>
         <v-btn 
          color="secondary"
          fab 
          elevation="0"
          x-small
          @click="search.edit = !search.edit"
          >
           <v-icon>mdi-pencil</v-icon>
         </v-btn>
      </v-col> -->
      <!-- </v-col> -->

      <!-- OLD DATA TABLE BEFORE ALGOLIA IMPLEMENTATION -->

      <!-- <v-row justify="center">
        <v-col cols="12">
           <v-data-table
            :headers="headers"
            :items="getUsersList"
            :options.sync="optionsUsers"
             hide-default-footer
            :loading="loading"
            item-key="uid"
            :footer-props="{'items-per-page-options':[10, 30, 50, 100]}"
            :items-per-page="optionsUsers.itemsPerPage"
            class="elevation-4"
            fixed-header
          >
          <template v-slot:[`item.profile`]="{ item }">
            <v-btn
              icon
              color="primary"
              @click="changeRouteUser(item)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.firstName`]="{ item }">
            <span @click="changeRouteUser(item)" style="cursor:pointer;">{{ item.firstName }}</span>
          </template>
          <template v-slot:[`item.lastName`]="{ item }">
            <span @click="changeRouteUser(item)" style="cursor:pointer;">{{ item.lastName }}</span>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <span @click="changeRouteUser(item)" style="cursor:pointer;">{{ item.email }}</span>
          </template>
          <template v-slot:[`item.creationTime`]="{ item }">
            <span>{{ item.creationTime ? timestampToStringDate(item.creationTime) : '' }}</span>
          </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                small
                color="secondary"
                class="mx-1 my-1"
                width="150"
                @click="openModal('restore', item.email, item)"
              >Restore password</v-btn>
              <v-btn
                small
                color="secondary"
                class="mx-1 my-1"
                width="150"
                @click="openModal('validate', item.email, item)"
              >Validate email</v-btn>
              <v-btn
                small
                color="secondary"
                class="mx-1 my-1"
                width="150"
                @click="changeRouteSendNotification(item)"
              >Send Notification</v-btn>
            </template>
            <template v-slot:footer="{}">
            <v-row justify="end">
              <v-col cols="1">
                <v-select
                  class="mr-auto"
                  v-model="optionsUsers.itemsPerPage"
                  :items="[5,10,15,20]"
                >
              </v-select>
              </v-col>
              <v-col cols="2" class="text-right mr-3" align-self="center">
              <v-btn color="primary" x-small class="mx-1" :disabled="optionsUsers.page === 1" @click="optionsUsers.page--">
                <v-icon size="15">
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <p class="d-inline">{{ optionsUsers.page }}</p>
              <v-btn color="primary" x-small class="mx-1" :disabled="getUsersList.length < optionsUsers.itemsPerPage || getUsersList.length === 0" @click="optionsUsers.page++">
                <v-icon size="15">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
              </v-col>
            </v-row>
          </template>
           </v-data-table>
         
        </v-col>
      </v-row> -->
    </v-row>
    <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
      <v-card>
        <!-- <v-card-title class="headline">Use Google's location service?</v-card-title> -->

        <v-card-text class="pa-8 text-center" v-if="dialog.type === 'restore'"
          >Sure you want to reset {{ dialog.email }} password?</v-card-text
        >
        <v-card-text class="pa-8 text-center" v-if="dialog.type === 'validate'"
          >Are you sure you want to validate the email of
          {{ dialog.email }}?</v-card-text
        >
        <v-card-text class="pa-8 text-center" v-if="dialog.type === 'disable'"
          >Are you sure you want to
          {{ dialog.item.disabled ? "enable" : "disable" }} the account of
          {{ dialog.email }}?</v-card-text
        >

        <!-- <v-card-actions class="pa-0"> -->
        <!-- <v-spacer></v-spacer> -->

        <v-btn
          color="third"
          tile
          width="150"
          class="pa-0 text--secondary"
          @click="closeModal()"
          >No</v-btn
        >

        <!-- <v-btn
          color="primary"
          tile
          width="150"
          class="pa-0"
          @click="goActionMail(dialog.type, dialog.email, dialog.item)"
        >Yes</v-btn> -->
        <v-btn
          color="primary"
          tile
          width="150"
          class="pa-0"
          @click="
            openDialogReAuth(
              'goActionMail',
              {},
              { type: dialog.type, email: dialog.email, item: dialog.item }
            )
          "
          >Yes</v-btn
        >
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>
    <modal-reauth
      :dialogReAuth="dialogReAuth"
      @isSuccessReAuth="isSuccessReAuth"
    ></modal-reauth>
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.text }}</v-snackbar
    >
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import algoliasearch from "algoliasearch/lite";
import modalReauth from "@/components/admin/modal-reauth.vue";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  name: "UserList",
  components: { modalReauth },
  data: () => ({
    algoliaFilters: "",
    filterOptions: [
      { label: "All", value: "" },
      { label: "Premium", value: "isPremium:true" },
      { label: "Free", value: "isPremium:false" },
    ],
    selectedFilter: "",
    freeUsers: [],
    loadingFreeUsers: false,
    premiumUsers: [],
    loadingPremiumUsers: false,
    switch: "",
    searched: {
      show: false,
      data: "",
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    dialogReAuth: {
      show: false,
      action: "",
      item: {},
      goActionProps: {},
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    dialog: {
      show: false,
      type: "",
      email: "",
      item: "",
    },
    headers: [
      { text: "", align: "center", value: "profile", sortable: false },
      {
        text: "First Name",
        align: "start",
        value: "firstName",
        sortable: false,
      },
      { text: "Last Name", align: "start", value: "lastName", sortable: false },
      {
        text: "Display Name",
        align: "start",
        value: "displayName",
        sortable: false,
      },
      { text: "Nickname", align: "start", value: "nickname", sortable: false },
      { text: "E-mail", align: "start", value: "email", sortable: false },
      {
        text: "Creation Date",
        align: "start",
        value: "creationTime",
        sortable: false,
      },
      // { text: "Enabled", align: "start", value: "status", sortable: false },
      { text: "Action", align: "center", value: "action", sortable: false },
    ],
    users: [],
    totalUsers: 0,
    loading: false,
    options: {},
    pageTokens: [],
    optionsUsers: {},
    search: {
      edit: true,
      values: {
        firstName: "",
        lastName: "",
        email: "",
      },
    },
    searchBox: "",
    itemsPerPage: 10,
    currentPage: 0,
    searchClient: algoliasearch(
      "SCII59RM36",
      "f935a55998c09178ae15d9d9d7a570a7"
    ),
    csvData: {
      href: null,
      download: null,
    },
    hitsPerPageOptions: [
      { label: "5 results", value: 5 },
      { label: "10 results", value: 10, default: true },
      { label: "20 results", value: 20 },
      { label: "50 results", value: 50 },
      { label: "100 results", value: 100 },
    ],
    loadingStorageData: false,
    initialUiState: undefined,
    fromDetail: false,
  }),
  created() {
    const vm = this;
    // console.log('algoliasearch', vm.searchClient);
    // const client = algoliasearch('SCII59RM36', 'f935a55998c09178ae15d9d9d7a570a7');
    // const index = client.initIndex('usersIndex');
    // index.search('query', {
    // }).then(( test ) => {
    //   console.log(test);
    // });
    // vm.getPaginatedUsers()
    // vm.setQuantityUsers()
    // console.log(vm.getActualUsersPage)
    console.log(this.$route);

    if (this.$route.params.previousRoute === "User Profile") {
      this.loadingStorageData = true;
      const tableState = localStorage.getItem("tableState");

      if (tableState && tableState !== "empty") {
        const tableStateObj = JSON.parse(tableState);

        let hitsOptions = [...this.hitsPerPageOptions];
        for (let index = 0; index < hitsOptions.length; index++) {
          if (hitsOptions[index].value === Number(tableStateObj.hitsPerPage)) {
            hitsOptions[index].default = true;
            this.itemsPerPage = hitsOptions[index].value;
          } else {
            hitsOptions[index].default = false;
          }
        }
        this.hitsPerPageOptions = hitsOptions;

        this.searchBox = tableStateObj.query;
        this.currentPage = tableStateObj.page;

        const algoliaInitial = {
          usersIndex: {
            query: tableStateObj.query,
            page: tableStateObj.page + 1,
          },
        };
        this.initialUiState = algoliaInitial;
        this.loadingStorageData = false;
        try {
          localStorage.removeItem("tableState");
        } catch (error) {
          console.error(error);
        }
      } else {
        this.loadingStorageData = false;
      }
    }
  },
  mounted() {
    const vm = this;

    // if (algoliaState) {
    //   this.algoliaHelper.setState(JSON.parse(algoliaState));
    // }
    //  vm.searchFunction();
  },
  methods: {
    async getFreeUsersData() {
      let vm = this;
      this.loadingFreeUsers = true;
      vm.$store
        .dispatch("getFreeUsersData")
        .then((res) => {
          console.log(res);
          this.freeUsers = res[0];
          this.loadingFreeUsers = false;
        })
        .catch((err) => {
          this.loadingFreeUsers = false;
          console.log(err);
        });
    },
    async getPremiumUsersData() {
      let vm = this;
      this.loadingPremiumUsers = true;
      vm.$store
        .dispatch("getPremiumUsersData")
        .then((res) => {
          console.log(res);
          this.premiumUsers = res;
          this.loadingPremiumUsers = false;
        })
        .catch((err) => {
          this.loadingPremiumUsers = false;
          console.log(err);
        });
    },
    async getPaginatedUsers() {
      const vm = this;
      try {
        vm.loading = true;
        await vm.$store.dispatch("GetPaginatedUsersList", {
          ...vm.optionsUsers,
          search: vm.search,
        });
        vm.loading = false;
      } catch (error) {
        vm.loading = false;
        console.log(error);
      }
    },
    async generateUsersCSV() {
      const vm = this;

      try {
        // vm.loading = true
        const users = await vm.$store.dispatch("GetAllUsersListForCSV");
        vm.convertToCSV(users);
        // vm.loading = false
      } catch (error) {
        vm.loading = false;
        console.log(error);
      }
    },
    convertToCSV(object) {
      const vm = this;
      const items = object;
      const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
      const header = Object.keys(items[0]);
      const csv = [
        header.join(","), // header row first
        ...items.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        ),
      ].join("\r\n");
      var blob = new Blob(["\ufeff", csv]);
      var url = URL.createObjectURL(blob);
      vm.csvData.href = url;
      vm.csvData.download = "users-created-before-7-12-2021.csv";
      console.log(csv);
    },
    isSuccessReAuth(dialogReAuthResponse) {
      const vm = this;
      switch (dialogReAuthResponse.action) {
        case "goActionMail":
          vm.goActionMail(
            dialogReAuthResponse.goActionProps.type,
            dialogReAuthResponse.goActionProps.email,
            dialogReAuthResponse.goActionProps.item
          );
          break;
        default:
          break;
      }
    },
    timestampToStringDate(timestamp) {
      const dateParsed = new Date(timestamp);
      return moment(dateParsed).format("MMMM Do YYYY");
    },
    getParsedDate(date) {
      let vm = this;
      return moment(date).format("DD/MM/YYYY");
    },
    openDialogReAuth(action, item, goActionProps) {
      let vm = this;
      console.log("goActionProps", goActionProps);
      vm.dialogReAuth.show = true;
      vm.dialogReAuth.action = action;
      vm.dialogReAuth.item = item;
      vm.dialogReAuth.goActionProps = goActionProps;
      vm.closeModal();
    },
    changeRouteUser(item) {
      let vm = this;
      vm.$router.push({ name: "User Profile", params: item }).catch((err) => {
        vm.snackbar.text =
          "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
    changeRouteSendNotification(item) {
      let vm = this;
      vm.$router
        .push({ name: "Push Notification Send", params: item })
        .catch((err) => {
          vm.snackbar.text =
            "Sorry, you don't have permissions to enter this view.";
          vm.snackbar.color = "red darken-2";
          vm.snackbar.snackbar = true;
        });
    },
    setUsers() {
      let vm = this;
      vm.selectPage();
      vm.loading = true;
      const sorts = {
        sortBy: vm.options.sortBy,
        sortDesc: vm.options.sortDesc,
        page: vm.options.page,
        itemsPerPage: vm.options.itemsPerPage,
        searchKey: "",
        pageTokens: vm.pageTokens,
      };

      vm.$store
        .dispatch("GetAllUsers", sorts)
        .then((res) => {
          vm.loading = false;
          vm.pageTokens[vm.options.page + 1] = res.token;
          // vm.users = res.res;
        })
        .catch((err) => {
          vm.loading = false;
        });
    },
    setQuantityUsers() {
      let vm = this;
      vm.$store
        .dispatch("GetQuantityUsers")
        .then((total) => {
          vm.getPaginatedUsers();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    openModal(type, email, item) {
      let vm = this;
      vm.dialog.show = true;
      vm.dialog.type = type;
      vm.dialog.email = email;
      vm.dialog.item = item;
    },
    closeModal() {
      let vm = this;
      vm.dialog.show = false;
      vm.dialog.type = "";
      vm.dialog.email = "";
      vm.dialog.item = "";
    },
    goActionMail(type, email, item) {
      let vm = this;
      console.log("type", type);
      console.log("email", email);
      console.log("item", item);
      vm.$emit("showOverlay", true);
      if (type == "validate") {
        let verificationData = {
          email: email,
          lang: item.lang,
          displayName: `${item.firstName ? item.firstName : ""} ${
            item.lastName ? item.lastName : ""
          }`,
        };

        vm.$store
          .dispatch("SendVerificationEmail", verificationData)
          .then((res) => {
            vm.$store.dispatch("GenerateLog", {
              user: verificationData.email,
              type: "validationEmail",
            });
            vm.snackbar.text = "Mail sended!";
            vm.snackbar.color = "green";
            vm.snackbar.snackbar = true;
            vm.$emit("showOverlay", false);
          })
          .catch((err) => {
            vm.snackbar.text = "Error sending mail!";
            vm.snackbar.color = "red";
            console.error(err);
            vm.$emit("showOverlay", false);
            vm.snackbar.snackbar = true;
          });
      } else if (type == "restore") {
        let resetPasswordData = {
          email: email,
          lang: item.lang,
          displayName: `${item.firstName ? item.firstName : ""} ${
            item.lastName ? item.lastName : ""
          }`,
        };
        vm.$store
          .dispatch("SendPasswordResetEmail", resetPasswordData)
          .then((res) => {
            vm.$store.dispatch("GenerateLog", {
              user: resetPasswordData.email,
              type: "restorePassword",
            });
            vm.snackbar.text = "Mail sended!";
            vm.snackbar.color = "green";
            vm.snackbar.snackbar = true;

            vm.$emit("showOverlay", false);
          })
          .catch((err) => {
            vm.snackbar.text = "Error sending mail!";
            vm.snackbar.color = "red";
            vm.snackbar.snackbar = true;
            vm.$emit("showOverlay", false);
          });
      } else if (type === "disable") {
        // let index = vm.users.indexOf(item);
        vm.disableUser(item);
      }
    },
    disableUser(user) {
      let vm = this;
      let indexActualPage = vm.getActualUsersPage.indexOf(user);
      let indexAllPage = vm.getAllUsers.indexOf(user);

      vm.dialog.show = false;
      vm.dialog.type = "";
      vm.dialog.email = "";
      vm.dialog.item = "";

      if (!user.adminLevel) {
        vm.$store
          .dispatch("DisableUser", {
            uid: user.uid,
            valueDisabled: !user.disabled,
          })
          .then((res) => {
            user.disabled = res.disabled;
            let updateData = {
              indexActualPage: indexActualPage,
              indexAllPage: indexAllPage,
              user: user,
            };

            vm.$store.dispatch("UpdateUser", updateData);
            vm.$store.dispatch("GenerateLog", {
              user: updateData.user.email,
              type: updateData.user.disabled ? "disabled" : "enabled",
            });

            vm.snackbar.text = `User ${
              res.disabled ? "disabled" : "enabled"
            } successfully`;
            vm.snackbar.color = "green";
            vm.$emit("showOverlay", false);
            vm.snackbar.snackbar = true;
          })
          .catch((err) => {
            console.error(err);
            vm.snackbar.text = `Error disabling user`;
            vm.snackbar.color = "red darken-2";
            vm.$emit("showOverlay", false);
            vm.snackbar.snackbar = true;
          });
      } else {
        vm.$emit("showOverlay", false);
        vm.snackbar.text = `You can't disable the admin`;
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      }
    },
    goSearch() {
      let vm = this;
      if (vm.optionsUsers.page !== 1) {
        vm.$set(vm.optionsUsers, "page", 1);
      } else {
        vm.getPaginatedUsers();
      }
      vm.search.edit = false;
    },
    searchUserByEmail() {
      let vm = this;
      vm.loading = true;
      vm.searched = {
        show: true,
        data: vm.search,
      };
      let email = {
        email: vm.search,
      };
      vm.pageTokens = [];
      vm.$store
        .dispatch("SearchByEmail", email)
        .then((res) => {
          vm.loading = false;
        })
        .catch((err) => {
          vm.loading = false;
          console.error(err);
        });
    },
    clearNewSearch() {},
    clearSearch() {
      let vm = this;
      vm.$store.dispatch("ResetValues");
      vm.searched.show = false;
      vm.search = "";
      vm.setUsers();
    },
    selectPage() {
      let vm = this;
      let sorts = {
        sortBy: vm.options.sortBy,
        sortDesc: vm.options.sortDesc,
        page: vm.options.page,
        itemsPerPage: vm.options.itemsPerPage,
        searchKey: "",
        pageTokens: vm.pageTokens,
      };
      vm.$store.dispatch("GetPageActual", sorts);
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "getAllUsers",
      "getActualUsersPage",
      "getQuantityUsers",
      "getUsersList",
    ]),
  },
  watch: {
    options: {
      handler(newData, oldData) {
        if (newData.itemsPerPage != oldData.itemsPerPage) {
          this.$store.dispatch("ResetValues");
          this.pageTokens = [];
          // this.selectPage();
        }
        if (
          this.getAllUsers.length < this.getQuantityUsers &&
          this.getActualUsersPage.length < this.getQuantityUsers
        ) {
          if (
            newData.page * newData.itemsPerPage > this.getQuantityUsers &&
            this.getAllUsers.length == this.getQuantityUsers
          ) {
            this.selectPage();
          } else {
            this.setUsers();
          }
        } else {
          this.selectPage();
        }
      },
      deep: true,
    },
    "optionsUsers.page": function () {
      // console.log('aaa', this.optionsUsers)
      this.getPaginatedUsers();
    },
    optionsUsers: {
      handler() {
        this.getPaginatedUsers();
      },
    },
    selectedFilter: {
      handler(newData, oldData) {
        this.algoliaFilters = newData;
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "User Profile") {
      const currentState = JSON.stringify({
        hitsPerPage: this.itemsPerPage,
        query: this.searchBox,
        page: this.currentPage,
      });
      localStorage.setItem("tableState", currentState);
    } else {
      localStorage.removeItem("tableState");
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "User Profile") {
      to.params.previousRoute = from.name;
    }
    // console.log(this.$route);
    // if (from.name === 'User Profile') {
    //   next({ ...to, query: { ...to.query, fromDetail: true } });
    // } else {

    // }
    next();
  },
  // destroyed(){
  //   let vm = this
  //   vm.$store.dispatch('GoUnsubscribeGetQuantityUsers');
  // }
};
</script>
<style lang="scss" scoped>
$dialog-max-height: 50%;

tr > td {
  padding: 0 !important;
}

.ais-SearchBox ::v-deep .ais-SearchBox-input:focus {
  border-color: #ec5c00;
}

.ais-HitsPerPage ::v-deep .ais-HitsPerPage-select:focus,
.ais-SortBy-select:focus {
  border-color: #ec5c00;
  box-shadow: none;
}

.v-data-table ::v-deep .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table ::v-deep .v-data-table__wrapper {
  overflow: unset;
}

.csv {
  width: 200px !important;
  height: 32px !important;
  text-transform: uppercase !important;
  border: none;
  background-color: #ec5c00 !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 6px;
}

.csv:hover {
  cursor: pointer;
}

.disabled {
  background-color: gray;
}

.radioLabel {
  margin: 0 10px 0 4px;
}

.radioContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>