<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="2">
        <p class="text-h6 text--secondary font-weight-bold">Coached Classes</p>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-btn text color="secondary" @click="goSettingsProTrain()">
          <v-icon left dark color="primary"> mdi-cog </v-icon>
          Tags Coached Classes
        </v-btn>
        <!-- <v-btn
          text
          color="secondary"
          @click="goProTrainTypeOfExercise()"
        >
        <v-icon left dark color="primary"> mdi-dumbbell </v-icon>
         Type of Exercise
        </v-btn> -->
        <!-- <v-menu
          ref="dateRangePickerRef"
          v-model="dateRangePicker.show"
          :close-on-content-click="false"
          :return-value.sync="dateRangePicker.range"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              :disabled="dateRangePicker.disabled"
              height="35"
              class="mx-5"
              label="Filter Between Date"
              prepend-inner-icon="mdi-calendar"
              outlined
              rounded
              filled
              dense
              readonly
              v-bind="attrs"
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="dateRangePicker.range"
            no-title
            color="primary"
            scrollable
            range
          >
            <v-spacer />
            <v-btn text color="primary" @click="dateRangePicker.show = false"> Cancel </v-btn>
            <v-btn
              color="primary"
              :disabled="dateRangePicker.range.length < 2"
              @click="$refs.dateRangePickerRef.save(dateRangePicker.range); searchByDateRange();"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu> -->
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-btn text color="secondary" @click="createProTrain()">
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          Create new Coached Class
        </v-btn>
        <!-- <v-btn
          text
          color="secondary"
          @click="goProTrainTypeOfBody()"
        >
        <v-icon left dark color="primary"> mdi-human-greeting </v-icon>
         Type of Body
        </v-btn> -->
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          label="Search for a Coached Class"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="pa-0 d-inline-flex align-center">
        <p class="ml-3 mb-0">
          <v-icon color="primary" class="mr-1" size="18">mdi-video</v-icon>Total
          reproductions:
          <span class="font-weight-bold primary--text">{{
            totalReproductions
          }}</span>
        </p>
        <p class="ml-3 mb-0">
          <v-icon color="primary" class="mr-1" size="18">mdi-eye</v-icon>Total
          dates filter:
          <span class="font-weight-bold primary--text" v-if="!loading">{{
            totalDatesFilter
          }}</span
          ><v-progress-circular
            indeterminate
            color="primary"
            size="20"
            v-else
          ></v-progress-circular>
        </p>
        <date-range-picker
          v-model="dateRange"
          class="range-picker-protrain ml-3"
          :date-range="dateRange"
          :single-date-picker="'range'"
          :auto-apply="true"
          ref="picker"
          @update="searchByDateRange()"
        >
          <template #input="picker" style="min-width: 350px">
            <v-icon color="primary">mdi-eye</v-icon>
            {{ picker ? dateRangeText : "" }}
          </template>
        </date-range-picker>
        <v-btn
          v-if="getAdmin.adminLevel === 3"
          class="ml-3"
          small
          color="red"
          dark
          @click="openDialogConfirmResetMassiveLeaderboard()"
        >
          <v-icon left dark color="white"> mdi-account-details </v-icon>
          Reset all Leaderboads
        </v-btn>
        <!-- <template v-else> 
          
        </template> -->
      </v-col>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="getProTrainersList"
          :sort-desc="true"
          :custom-sort="customSort"
          sort-by="counterReproductions"
          item-key="id"
          fixed-header
          class="elevation-4"
          :page.sync="page"
          @update:page="onPageChange"
          :items-per-page.sync="itemsPerPage"
          @update:items-per-page="onItemsPerPageUpdate"
          :sortBy.sync="sortBy"
          @update:sortBy="onSortByChange"
        >
          <template v-slot:[`item.selectedImagePreview`]="{ item }">
            <v-tooltip top color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <img
                  v-if="item.selectedImagePreview.link"
                  v-bind="attrs"
                  v-on="on"
                  class="protrain-image-preview"
                  style="cursor: pointer"
                  @click="
                    openDialogPreviewImage(item.selectedImagePreview.link)
                  "
                  :src="item.selectedImagePreview.link"
                />
              </template>
              <span>Show Image</span>
            </v-tooltip>
          </template>
          <!-- <template v-slot:[`item.sizeImg`]="{ item }">
            {{ item.selectedImagePreview.size }} {{ item.selectedImagePreview.sizeUnit }}
          </template> -->
          <template v-slot:[`item.previewPrincipalVideo`]="{ item }">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="primary"
                  @click="openDialogPreviewVideo(item)"
                >
                  <v-icon>mdi-message-video</v-icon>
                </v-btn>
              </template>
              <span>Show Video</span>
            </v-tooltip>
          </template>
          <!-- <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template> -->
          <!-- <template v-slot:[`item.id`]="{ item }">
            {{ getProTrainersCounterReproductor[item.id] ? getProTrainersCounterReproductor[item.id]: 0 }}
          </template> -->
          <template v-slot:[`item.name`]="{ item }">
            <div class="nameSlot">{{ item.name }}</div>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <div class="descriptionSlot">{{ item.description }}</div>
          </template>
          <template v-slot:[`item.takedWorkouts`]="{ item }">
            {{ item.takedWorkouts ? item.takedWorkouts.length : 0 }}
          </template>
          <template v-slot:[`item.listPrincipalVideoSections`]="{ item }">
            {{ item.listPrincipalVideoSections.length }}
          </template>
          <template v-slot:[`item.selectedAccessories`]="{ item }">
            {{ item.selectedAccessories.length }}
          </template>
          <!-- <template v-slot:[`item.withSmartMaxpro`]="{ item }">
            <h4 v-if="item.withSmartMaxpro" class="primary--text">Yes</h4>
            <template v-else>
              <h4 class="secondary--text">No</h4>
            </template>
          </template> -->
          <template v-slot:[`item.leaderboard`]="{ item }">
            <v-btn icon color="primary" @click="openDialogLeaderBoard(item)">
              <v-icon>mdi-account-details</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.dynamicLink`]="{ item }">
            <v-btn icon color="primary" @click="openDialogDynamicLink(item)">
              <v-icon>mdi-link</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <v-switch
              v-if="item"
              color="primary"
              class="center-switch"
              :input-value="item.enabled"
              @click="updateValue(item, 'enabled', !item.enabled)"
              flat
            ></v-switch>
            <!-- @click.stop="disableProTrainer(item)" -->
          </template>
          <template v-slot:[`item.withSmartMaxpro`]="{ item }">
            <v-switch
              v-if="item"
              color="primary"
              class="center-switch"
              :input-value="item.withSmartMaxpro"
              @click="
                updateValue(item, 'withSmartMaxpro', !item.withSmartMaxpro)
              "
              flat
            ></v-switch>
          </template>
          <template v-slot:[`item.isFree`]="{ item }">
            <v-switch
              v-if="item"
              color="primary"
              class="center-switch"
              :input-value="item.isFree"
              @click="updateValue(item, 'isFree', !item.isFree)"
              flat
            ></v-switch>
          </template>
          <template v-slot:[`item.isGrandFathered`]="{ item }">
            <v-switch
              v-if="item"
              color="primary"
              class="center-switch"
              :input-value="item.isGrandFathered"
              @click="
                updateValue(item, 'isGrandFathered', !item.isGrandFathered)
              "
              flat
            ></v-switch>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <options-button
              :onEdit="
                () => {
                  changeRouteProTrainer(item);
                }
              "
              :onDuplicate="undefined"
              :onDelete="
                () => {
                  openDialogConfirm(item);
                }
              "
              :itemId="item.id"
              :key="item.id"
            />
          </template>
          <!-- <template v-slot:footer="{}">
            <v-row justify="end">
              <v-col cols="1">
                <v-select
                  class="mr-auto"
                  v-model="itemsPerPage"
                  :items="[5,10,15,20,30]"
                >
              </v-select>
              </v-col>
              <v-col cols="2" class="text-right mr-3" align-self="center">
              <v-btn color="primary" x-small class="mx-1" :disabled="pageCounter === 1" @click="goPrevPageProTrainers()">
                <v-icon size="15">
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <p class="d-inline">{{ pageCounter }}</p>
              <v-btn color="primary" x-small class="mx-1" :disabled="getProTrainersList.length < itemsPerPage || getProTrainersList.length === 0" @click="goNextPageProTrainers()">
                <v-icon size="15">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
              </v-col>
            </v-row>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogPreviewVideo.show"
      transition="dialog-bottom-transition"
      max-width="700"
    >
      <v-card class="text-center">
        <v-card-actions class="pa-0">
          <v-btn
            icon
            color="secondary"
            class="ml-3 mt-2"
            @click="closeDialogVideoPreview()"
          >
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <img
          src="@/assets/logo_x.png"
          style="vertical-align: middle"
          class="mx-2 d-inline"
          width="20"
        />
        <p class="font-weight-bold secondary--text d-inline">
          {{ dialogPreviewVideo.name }}
        </p>
        <v-card-text class="pb-8 mt-3">
          <video
            v-if="dialogPreviewVideo.video"
            ref="listProTrainVideoPreview"
            width="100%"
            height="350px"
            controls
            class="mx-auto"
            :src="dialogPreviewVideo.video ? dialogPreviewVideo.video : ''"
          ></video>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogLeaderBoard.show"
      transition="dialog-bottom-transition"
      persistent
      max-width="700"
    >
      <v-card
        v-if="!dialogLeaderBoard.loading"
        class="text-center"
        height="600"
      >
        <v-card-actions class="pa-0 d-flex justify-space-between">
          <v-btn
            icon
            color="secondary"
            class="ml-3 mt-2"
            @click="closeDialogLeaderBoard()"
          >
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="red"
            small
            dark
            class="mr-3 mt-2"
            :disabled="dialogConfirmResetLeader.loading"
            :loading="dialogConfirmResetLeader.loading"
            @click="openDialogConfirmResetLeaderboard(dialogLeaderBoard.id)"
          >
            Reset Leaders
          </v-btn>
        </v-card-actions>

        <p class="font-weight-bold secondary--text d-inline">
          {{ dialogLeaderBoard.name }}
        </p>
        <v-card-text
          v-if="getProTrainerLeaderboard && getProTrainerLeaderboard.length > 0"
        >
          <v-list>
            <v-list-item
              v-for="leader in getProTrainerLeaderboard"
              :key="leader.user"
            >
              <v-list-item-avatar>
                <v-img
                  :src="
                    leader.userInfo.photoURL
                      ? leader.userInfo.photoURL
                      : require('@/assets/default-avatar.png')
                  "
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  >{{ leader.userInfo.firstName }}
                  {{ leader.userInfo.lastName }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action class="primary--text font-weight-bold">
                {{ leader.score }}
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-text
          v-else
          class="d-flex align-center justify-center"
          style="height: 90%"
        >
          <p class="secondary--text text-center d-inline">
            No leaderboards available
          </p>
        </v-card-text>
      </v-card>
      <v-card v-else class="d-flex justify-center align-center" height="600">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPreviewImage.show" max-width="800">
      <v-card style="line-height: 0; !important;">
        <v-btn
          fab
          x-small
          color="red"
          style="position: absolute; top: 5px; left: 5px"
          @click="closeDialogImagePreview()"
        >
          <v-icon size="15" color="white">mdi-close</v-icon>
        </v-btn>
        <img :src="dialogPreviewImage.img" style="width: 100%; height: 100%" />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDynamicLink.show"
      transition="dialog-bottom-transition"
      max-width="700"
    >
      <v-card class="text-center">
        <v-card-actions class="pa-0">
          <v-btn
            icon
            color="secondary"
            class="ml-3 mt-2"
            @click="closeDialogDynamicLink()"
          >
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="pb-8 mt-3">
          <div class="text-center d-flex align-center mt-5">
            <template
              v-if="
                dialogDynamicLink.proTrainer &&
                dialogDynamicLink.proTrainer.dynamicLink &&
                dialogDynamicLink.proTrainer.dynamicLink.length > 0
              "
            >
              <v-text-field
                v-model="dialogDynamicLink.proTrainer.dynamicLink"
                ref="proTrainDynamicLinkRef"
                hide-details="auto"
                maxlength="4"
                class="centered-input"
                readonly
                solo
                flat
                background-color="#efefef"
              ></v-text-field>
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="primary"
                    @click="
                      copyLinkProTrain(dialogDynamicLink.proTrainer.dynamicLink)
                    "
                  >
                    <v-icon>mdi-plus-box-multiple</v-icon>
                  </v-btn>
                </template>
                <span>Copy to clipboard</span>
              </v-tooltip>
            </template>
            <template v-else>
              <p class="secondary--text subtitle-1 mx-auto">
                There is no dynamic link for this workout yet
              </p>
            </template>
          </div>
          <div class="text-center mb-3">
            <v-btn
              class="mt-3 text-center"
              text
              color="secondary"
              :disabled="dialogDynamicLink.loading"
              :loading="dialogDynamicLink.loading"
              @click="generateDynamicLink()"
            >
              <v-icon left dark color="primary"> mdi-link-plus </v-icon>
              Generate Dynamic Link
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
      <v-card class="text-center">
        <!-- <v-card-title class="headline">Use Google's location service?</v-card-title> -->
        <v-icon class="text-center ma-2" size="120" color="primary"
          >mdi-alert</v-icon
        >
        <v-card-text class="pa-4 text-center"
          >This action is irreversible and will delete all data about the Coach
          Class. Including the video, sections and information</v-card-text
        >
        <v-card-text class="text-center">Are you sure?</v-card-text>

        <!-- <v-card-actions class="pa-0"> -->
        <!-- <v-spacer></v-spacer> -->

        <v-btn
          color="third"
          tile
          width="150"
          class="pa-0 text--secondary"
          @click="closeDialogConfirm()"
          >No</v-btn
        >

        <v-btn
          color="primary"
          tile
          width="150"
          class="pa-0"
          @click="openDialogReAuth('deleteProTrainer', dialog.item)"
          >Yes</v-btn
        >
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogConfirmResetLeader.show"
      width="300"
      class="rounded-xl"
    >
      <v-card class="text-center">
        <!-- <v-card-title class="headline">Use Google's location service?</v-card-title> -->
        <v-icon class="text-center ma-2" size="120" color="primary"
          >mdi-alert</v-icon
        >
        <v-card-text class="pa-4 text-center"
          >This action is irreversible and will delete all data about the
          Leaderboard.</v-card-text
        >
        <v-card-text class="text-center">Are you sure?</v-card-text>

        <!-- <v-card-actions class="pa-0"> -->
        <!-- <v-spacer></v-spacer> -->

        <v-btn
          color="third"
          tile
          width="150"
          class="pa-0 text--secondary"
          @click="closeDialogConfirmResetLeaderboard()"
          >No</v-btn
        >

        <v-btn
          color="primary"
          tile
          width="150"
          class="pa-0"
          @click="
            openDialogReAuth(
              'resetLeaderBoardProTrain',
              dialogConfirmResetLeader.item
            )
          "
          >Yes</v-btn
        >
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogConfirmResetMassiveLeader.show"
      width="300"
      class="rounded-xl"
    >
      <v-card class="text-center">
        <!-- <v-card-title class="headline">Use Google's location service?</v-card-title> -->
        <v-icon class="text-center ma-2" size="120" color="primary"
          >mdi-alert</v-icon
        >
        <v-card-text class="pa-4 text-center"
          >Caution! This action is irreversible and will delete all the
          leaderboards of all Coached Classes</v-card-text
        >
        <v-card-text class="text-center">Are you sure?</v-card-text>

        <!-- <v-card-actions class="pa-0"> -->
        <!-- <v-spacer></v-spacer> -->

        <v-btn
          color="third"
          tile
          width="150"
          class="pa-0 text--secondary"
          @click="closeDialogConfirmResetMassiveLeaderboard()"
          >No</v-btn
        >

        <v-btn
          color="primary"
          tile
          width="150"
          class="pa-0"
          @click="
            openDialogReAuth(
              'resetMassiveLeaderBoardProTrain',
              dialogConfirmResetMassiveLeader.item
            )
          "
          >Yes</v-btn
        >
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.text }}</v-snackbar
    >
    <modal-reauth
      :dialogReAuth="dialogReAuth"
      @isSuccessReAuth="isSuccessReAuth"
    ></modal-reauth>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import modalReauth from "@/components/admin/modal-reauth.vue";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import OptionsButton from "@/components/admin/OptionsButton.vue";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;

Vue.use(VueClipboard);
export default {
  name: "ProTrainers",
  components: { modalReauth, DateRangePicker,OptionsButton },
  data: () => ({
    dialogReAuth: {
      show: false,
      action: "",
      item: {},
    },
    dateRange: {
      startDate: null,
      endDate: null,
    },
    search: "",
    dialog: {
      show: false,
      item: {},
    },
    dialogConfirmResetLeader: {
      show: false,
      item: {},
      loading: false,
    },
    dialogConfirmResetMassiveLeader: {
      show: false,
      item: {},
    },
    dateRangePicker: {
      disabled: false,
      show: false,
      range: [],
    },
    headers: [
      // {
      //   text: "",
      //   align: "center",
      //   value: "viewMore",
      //   sortable: false,
      // },
      {
        text: "",
        align: "center",
        value: "selectedImagePreview",
        sortable: false,
      },
      //{ text: "Img size", align: "center", value: "sizeImg", sortable: false },
      {
        text: "Preview",
        align: "center",
        value: "previewPrincipalVideo",
        sortable: false,
      },
      //{ text: "Order Number", align: "center", value: "orderNumber", sortable: true },
      {
        text: "Name",
        align: "center",
        value: "name",
        sortable: true,
      },
      {
        text: "Description",
        align: "center",
        value: "description",
        sortable: false,
      },
      {
        text: "Reproductions",
        align: "center",
        value: "counterReproductions",
        sortable: true,
      },
      //{
      //text: "Dates Filter",
      //align: "center",
      //value: "takedWorkouts",
      //sortable: true,
      //},
      {
        text: "Sections",
        align: "center",
        value: "listPrincipalVideoSections",
        sortable: true,
      },
      // {
      //   text: "Accessories",
      //   align: "center",
      //   value: "selectedAccessories",
      //   sortable: true,
      // },
      {
        text: "Type",
        align: "center",
        value: "selectedChallengeWorkout.name",
        sortable: true,
      },
      {
        text: "Difficulty",
        align: "center",
        value: "selectedDifficulty",
        sortable: true,
      },
      {
        text: "Duration",
        align: "center",
        value: "totalDuration",
        sortable: true,
      },
      {
        text: "Link",
        align: "center",
        value: "dynamicLink",
        sortable: false,
      },
      {
        text: "Enabled",
        align: "center",
        value: "enabled",
        sortable: true,
      },
      {
        text: "W/Maxpro",
        align: "center",
        value: "withSmartMaxpro",
        sortable: true,
      },
      {
        text: "Free",
        align: "center",
        value: "isFree",
        sortable: true,
      },
      {
        text: "Grandfathered",
        align: "center",
        value: "isGrandFathered",
        sortable: true,
      },
      {
        text: "Leaderboard",
        align: "center",
        value: "leaderboard",
        sortable: false,
      },
      {
        text: "Action",
        align: "center",
        value: "action",
        sortable: false,
      },
    ],
    itemsPerPage: 10,
    pageCounter: 1,
    search: "",
    loading: true,
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    dialogPreviewVideo: {
      show: false,
      video: "",
      name: "",
    },
    dialogPreviewImage: {
      show: false,
      video: "",
    },
    dialogDynamicLink: {
      show: false,
      loading: false,
      proTrainer: {},
    },
    dialogLeaderBoard: {
      show: false,
      name: "",
      id: "",
      loading: false,
    },
    page: 0,
    itemsPerPage: 10,
    sortBy: "name.ENG",
  }),
  created() {
    let vm = this;
    vm.getDataToInit();
    if (this.$route.params.previousRoute === "CoachedClasses Edit Set") {
      const tableState = localStorage.getItem("classesTableState");
      if (tableState) {
        const tableStateObj = JSON.parse(tableState);
        this.search = tableStateObj.query;
        this.itemsPerPage = tableStateObj.hitsPerPage;
        this.page = tableStateObj.page;
      }
    } else {
      this.itemsPerPage = -1;
    }
    // vm.loading = true;
    // vm.dateRange = vm.$store.state.ProTrainersModule.dateRange
    // vm.$store
    //   .dispatch("GetProTrainersList")
    // vm.$set(vm.dateRange, 'startDate', new Date())
    // vm.$set(vm.dateRange, 'endDate', new Date())
    //   .then((res) => {
    // vm.$store
    // .dispatch("GetProTrainersCounterReproductor")
    // .then((resCounter) => {
    //    vm.getProTrainers()
    // })
    // .catch((errCounter) => {
    //   console.error(errCounter);
    //   vm.loading = false;
    // });
    // })
    // .catch((err) => {
    //   console.error(err);
    //   vm.loading = false;
    // });
  },
  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === "orderNumber") {
          if (!isDesc[0]) {
            if (b.orderNumber === "") {
              return b.orderNumber - a.orderNumber;
            } else {
              return a.orderNumber - b.orderNumber;
            }
          } else {
            return b.orderNumber - a.orderNumber;
          }
        } else {
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    getDataToInit() {
      const vm = this;
      vm.loading = true;
      vm.dateRange = vm.$store.state.ProTrainersModule.dateRange;
      vm.$store
        .dispatch("GetProTrainersCounterReproductor")
        .then((resCounter) => {
          vm.getProTrainers();
        })
        .catch((errCounter) => {
          console.error(errCounter);
          vm.loading = false;
        });
    },
    getProTrainers() {
      let vm = this;
      vm.$store
        .dispatch("GetProTrainersList")
        .then((res) => {
          vm.searchByDateRange();
          console.log("getProTrainersList", vm.getProTrainersList);
          vm.loading = false;
        })
        .catch((err) => {
          console.error(err);
          vm.loading = false;
        });
    },
    getFirstPageProTrainers() {
      let vm = this;
      vm.$store
        .dispatch("GetProTrainersPaginatedFirstPage", vm.itemsPerPage)
        .then((res) => {
          vm.pageCounter = 1;
          vm.loading = false;
        })
        .catch((err) => {
          console.error(err);
          vm.loading = false;
        });
    },
    goNextPageProTrainers() {
      let vm = this;
      vm.$store
        .dispatch("GetProTrainersNextPage", vm.itemsPerPage)
        .then((res) => {
          if (res.length > 0) {
            vm.pageCounter = vm.pageCounter + 1;
          }
          vm.loading = false;
        })
        .catch((err) => {
          console.error(err);
          vm.loading = false;
        });
    },
    goPrevPageProTrainers() {
      let vm = this;
      vm.$store
        .dispatch("GetProTrainersPrevPage", vm.itemsPerPage)
        .then((res) => {
          vm.pageCounter = vm.pageCounter - 1;
          vm.loading = false;
        })
        .catch((err) => {
          console.error(err);
          vm.loading = false;
        });
    },
    async openDialogLeaderBoard(proTrain) {
      const vm = this;
      try {
        vm.dialogLeaderBoard.name = proTrain.name;
        vm.dialogLeaderBoard.id = proTrain.id;
        vm.dialogLeaderBoard.show = true;
        vm.dialogLeaderBoard.loading = true;
        await vm.$store.dispatch("GetLeaderBoardProTrain", proTrain);
        vm.dialogLeaderBoard.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    closeDialogLeaderBoard() {
      const vm = this;
      vm.dialogLeaderBoard = {
        show: false,
        name: "",
        id: "",
        loading: false,
      };
      vm.$store.dispatch("ClearLeaderBoardProTrain");
    },
    async updateValue(coachedClass, key, value) {
      const vm = this;
      await vm.$store.dispatch("UpdateClassValue", {
        id: coachedClass.id,
        data: { [key]: value },
      });
      await vm.$store.dispatch("GenerateLog", {
        id: coachedClass.id,
        title: coachedClass.name,
        type: "editedProtrain",
      });

      let emitData = {
        snackbar: true,
        btn: "",
        y: "bottom",
        x: null,
        mode: "",
        timeout: 2000,
        text: "Coached class edited successfully",
        color: "success",
      };
      vm.$emit("showSnackBar", emitData);
      vm.getDataToInit();
    },
    async isSuccessReAuth(dialogReAuthResponse) {
      const vm = this;
      switch (dialogReAuthResponse.action) {
        case "deleteProTrainer":
          vm.deleteProTrainer(dialogReAuthResponse.item);
          break;
        case "resetLeaderBoardProTrain":
          vm.closeDialogConfirmResetLeaderboard();
          await vm.resetLeaderBoardProTrain(dialogReAuthResponse.item.id);
          break;
        case "resetMassiveLeaderBoardProTrain":
          vm.closeDialogConfirmResetMassiveLeaderboard();
          await vm.resetMassiveLeaderboardsProTrain();
          break;
        default:
          break;
      }
    },
    openDialogReAuth(action, item) {
      let vm = this;
      vm.dialogReAuth.show = true;
      vm.dialogReAuth.action = action;
      vm.dialogReAuth.item = item;
      vm.closeDialogConfirm();
    },
    async resetLeaderBoardProTrain(id) {
      const vm = this;
      try {
        vm.dialogConfirmResetLeader.loading = true;
        await vm.$store.dispatch("ResetLeadeboardProTrain", {
          id: id,
          batchSize: 5,
        });
        vm.dialogConfirmResetLeader.loading = false;
        vm.dialogLeaderBoard.loading = true;
        await vm.$store.dispatch("GetLeaderBoardProTrain", { id: id });
        await vm.$store.dispatch("GenerateLog", {
          id: id,
          title: vm.dialogLeaderBoard.name,
          type: "resetLeaderBoardProTrain",
        });
        vm.dialogLeaderBoard.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async resetMassiveLeaderboardsProTrain() {
      const vm = this;
      try {
        vm.$emit("showOverlay", true);
        await vm.$store.dispatch("ResetMassiveAllLeadeboardsProTrain");
        vm.$emit("showOverlay", false);
        let emitData = {
          snackbar: true,
          btn: "",
          y: "bottom",
          x: null,
          mode: "",
          timeout: 2000,
          text: "All Leaderboards have been successfully reset",
          color: "success",
        };
        //  vm.$emit("showSnackBar", emitData);
      } catch (error) {
        vm.$emit("showOverlay", false);
        let emitData = {
          snackbar: true,
          btn: "",
          y: "bottom",
          x: null,
          mode: "",
          timeout: 2000,
          text: "Error Reseting all Leaderboards",
          color: "red",
        };
        vm.$emit("showSnackBar", emitData);
      }
    },
    searchByDateRange() {
      let vm = this;
      vm.dateRangePicker.disabled = true;
      vm.loading = true;

      const datesChanged = {
        startDate: new Date(vm.dateRange.startDate.setHours(0, 0, 0, 0)),
        endDate: new Date(vm.dateRange.endDate.setHours(23, 59, 59, 999)),
      };

      this.$store.commit("SET_DATE_RANGE", datesChanged);

      const dateRangeQuery = {
        startDate: vm.getProTrainDateRanges.startDate,
        endDate: vm.getProTrainDateRanges.endDate,
      };

      vm.$store
        .dispatch("GetProTrainersTakenInRangeDate", dateRangeQuery)
        .then((res) => {
          vm.dateRangePicker.disabled = false;
          vm.loading = false;
        })
        .catch((err) => {
          vm.dateRangePicker.disabled = false;
          console.error(err);
          vm.loading = false;
        });
    },
    parseToTimestamp(date) {
      date = date.split("-");
      var timestamp = new Date(date[0], date[1] - 1, date[2]);
      return timestamp;
    },
    changeRouteProTrainer(item) {
      let vm = this;
      vm.$router
        .push({ name: "CoachedClasses Edit Set", params: item })
        .catch((err) => {
          vm.snackbar.text =
            "Sorry, you don't have permissions to enter this view.";
          vm.snackbar.color = "red darken-2";
          vm.snackbar.snackbar = true;
        });
    },
    goSettingsProTrain() {
      let vm = this;
      vm.$router.push({ name: "CoachedClasses Settings" }).catch((err) => {
        vm.snackbar.text =
          "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
    goProTrainTypeOfBody() {
      let vm = this;
      vm.$router.push({ name: "CoachedClasses Type Of Body" }).catch((err) => {
        vm.snackbar.text =
          "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
    goProTrainTypeOfExercise() {
      let vm = this;
      vm.$router
        .push({ name: "CoachedClasses Type Of Exercise" })
        .catch((err) => {
          vm.snackbar.text =
            "Sorry, you don't have permissions to enter this view.";
          vm.snackbar.color = "red darken-2";
          vm.snackbar.snackbar = true;
        });
    },
    createProTrain() {
      let vm = this;
      vm.$router.push({ name: "CoachedClasses Create Set" }).catch((err) => {
        vm.snackbar.text =
          "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
    disableProTrainer(proTrainer) {
      const vm = this;
      let data = {
        id: proTrainer.id,
        enabled: !proTrainer.enabled,
      };
      vm.$store
        .dispatch("UpdateProTrainer", data)
        .then((res) => {
          // vm.loading = false;
        })
        .catch((err) => {
          console.error(err);
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error disabling Coached Class",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        });
    },
    deleteProTrainer(proTrainer) {
      const vm = this;
      vm.$emit("showOverlay", true);
      vm.$store
        .dispatch("DeleteProTrainer", proTrainer)
        .then((res) => {
          vm.$store.dispatch("GenerateLog", {
            id: proTrainer.id,
            title: proTrainer.name,
            type: "deletedProtrain",
          });
          vm.getDataToInit();
          vm.$emit("showOverlay", false);
          // vm.loading = false;
        })
        .catch((err) => {
          vm.$emit("showOverlay", false);
          console.error(err);
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error deleting Coached Class",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        });
    },
    openDialogConfirmResetLeaderboard(id) {
      let vm = this;
      vm.dialogConfirmResetLeader.show = true;
      vm.dialogConfirmResetLeader.item = { id: id };
    },
    openDialogConfirmResetMassiveLeaderboard() {
      let vm = this;
      vm.dialogConfirmResetMassiveLeader.show = true;
      vm.dialogConfirmResetMassiveLeader.item = {};
    },
    openDialogConfirm(item) {
      let vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
    },
    closeDialogConfirm() {
      let vm = this;
      vm.dialog.show = false;
      vm.dialog.item = "";
    },
    closeDialogConfirmResetLeaderboard() {
      let vm = this;
      vm.dialogConfirmResetLeader.show = false;
      vm.dialogConfirmResetLeader.item = {};
    },
    closeDialogConfirmResetMassiveLeaderboard() {
      let vm = this;
      vm.dialogConfirmResetMassiveLeader.show = false;
      vm.dialogConfirmResetMassiveLeader.item = {};
    },
    openDialogPreviewVideo(proTrain) {
      const vm = this;
      vm.dialogPreviewVideo = {
        show: true,
        video:
          proTrain.principalVideo && proTrain.principalVideo.link
            ? proTrain.principalVideo.link
            : "",
        name: proTrain.name,
      };
    },
    closeDialogVideoPreview() {
      const vm = this;
      vm.dialogPreviewVideo = {
        show: false,
        video: "",
        name: "",
      };
    },
    openDialogPreviewImage(img) {
      const vm = this;
      vm.dialogPreviewImage = {
        show: true,
        img: img ? img : "",
      };
    },
    closeDialogImagePreview() {
      const vm = this;
      vm.dialogPreviewImage.show = false;
      // vm.dialogPreviewImage = {
      //   show: false,
      //   img: ''
      // }
    },
    openDialogDynamicLink(proTrainer) {
      const vm = this;
      vm.dialogDynamicLink = {
        show: true,
        loading: false,
        proTrainer,
      };
    },
    closeDialogDynamicLink() {
      const vm = this;
      vm.dialogDynamicLink = {
        show: false,
        loading: false,
        proTrainer: {},
      };
    },
    onErrorCopyLinkProTrain() {
      const vm = this;
      vm.snackbar.text = "Error coping link !";
      vm.snackbar.color = "red";
      vm.snackbar.snackbar = true;
    },
    copyLinkProTrain(link) {
      const vm = this;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          vm.snackbar.text = "Copied !";
          vm.snackbar.color = "dark";
          vm.snackbar.snackbar = true;
        })
        .catch((err) => {
          vm.snackbar.text = "Error coping link !";
          vm.snackbar.color = "red";
          vm.snackbar.snackbar = true;
        });
    },
    generateDynamicLink() {
      const vm = this;
      vm.dialogDynamicLink.loading = true;

      const payload = {
        routeName: "coachedClasses",
        title: vm.dialogDynamicLink.proTrainer.name,
        id: vm.dialogDynamicLink.proTrainer.id,
        img: vm.dialogDynamicLink.proTrainer.selectedImagePreview.link,
      };
      vm.$store
        .dispatch("GenerateDynamicLink", payload)
        .then((dynamicLinks) => {
          vm.dialogDynamicLink.loading = false;
          vm.dialogDynamicLink.proTrainer.dynamicLink = dynamicLinks.shortLink;
          vm.updateProTrain(vm.dialogDynamicLink.proTrainer)
            .then((proTrainer) => {
              // vm.closeDialogDynamicLink()
            })
            .catch((errUpdate) => {
              vm.dialogDynamicLink.loading = false;
              console.error(errUpdate);
            });
        })
        .catch((err) => {
          vm.dialogDynamicLink.loading = false;
          console.error(err);
        });
    },
    updateProTrain(proTrainer) {
      let vm = this;
      return new Promise((resolve, reject) => {
        vm.$store
          .dispatch("UpdateProTrainer", proTrainer)
          .then((id) => {
            vm.$store.dispatch("GenerateLog", {
              id: id,
              title: proTrainer.name,
              type: "editedProtrain",
            });

            let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Coached Class Updated successfully",
              color: "success",
            };
            vm.$emit("showSnackBar", emitData);
            vm.getDataToInit();
            resolve(proTrainer);
          })
          .catch((err) => {
            let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Error editing Coached Class",
              color: "red",
            };
            vm.$emit("showSnackBar", emitData);
            reject(err);
          });
      });
    },
    onPageChange(page) {
      console.log(page);
    },
    onItemsPerPageUpdate(items) {
      console.log(items);
    },
    onSortByChange(sortBy, test) {
      console.log(sortBy, test);
    },
  },
  destroyed() {
    // let vm = this;
    // vm.$store.dispatch("GoUnsuscribeGetProTrainersList");
    // vm.$store.dispatch("GoUnsuscribeGetProTrainersCounterReproductor");
  },
  computed: {
    dateRangeText() {
      let startDate = moment(this.getProTrainDateRanges.startDate).format(
        "YYYY-MM-DD"
      );
      let endDate = moment(this.getProTrainDateRanges.endDate).format(
        "YYYY-MM-DD"
      );
      if (startDate !== endDate) {
        return `${startDate} to ${endDate}`;
      } else {
        return startDate;
      }
    },
    totalReproductions() {
      return Object.values(this.getProTrainersCounterReproductor).reduce(
        (counter, proTrain) => proTrain + counter,
        0
      );
    },
    totalDatesFilter() {
      let counterTotal = this.getProTrainersList.reduce(
        (counter, proTrain) => proTrain.takedWorkoutsCounter + counter,
        0
      );
      if (!Number.isNaN(counterTotal)) {
        return counterTotal;
      }
    },
    ...mapGetters([
      "getProTrainersList",
      "getProTrainersCounterReproductor",
      "getProTrainDateRanges",
      "getProTrainerLeaderboard",
      "getAdmin",
    ]),
  },
  beforeRouteLeave(to, from, next) {
    console.log(to);
    if (to.name === "CoachedClasses Edit Set") {
      const currentState = JSON.stringify({
        hitsPerPage: this.itemsPerPage,
        query: this.search,
        page: this.page,
      });
      localStorage.setItem("classesTableState", currentState);
    } else {
      localStorage.removeItem("classesTableState");
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "CoachedClasses Edit Set") {
      to.params.previousRoute = from.name;
    }
    next();
  },
};
</script>
<style scoped>
.protrain-image-preview {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  vertical-align: middle;
}
.center-switch > .v-input__control > .v-input__slot {
  justify-content: center !important;
}
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.vue-daterange-picker /deep/ .daterangepicker td.in-range {
  background-color: #ec5b014b !important;
}
.vue-daterange-picker /deep/ .daterangepicker td.active {
  background-color: #ec5b01 !important;
}
.vue-daterange-picker /deep/ .calendars-container {
  width: 0;
}
.vue-daterange-picker /deep/ .daterangepicker .ranges li.active {
  background-color: #ec5b01;
}
.vue-daterange-picker /deep/ .reportrange-text {
  transition: all 1s ease-in-out;
  background-color: #e1e1e1 !important;
  border-radius: 20px;
  border: 1px solid #8c8c8c;
}
.descriptionSlot {
  max-width: 12vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.nameSlot {
  max-width: 8vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>