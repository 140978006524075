<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="2">
        <p class="text-h6 text--secondary font-weight-bold">Lives List</p>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-btn text color="secondary" @click="changeRouteFilters()">
          <v-icon left dark color="primary"> mdi-cog </v-icon>
          Filters
        </v-btn>
        <v-btn text color="secondary" @click="createLive()">
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          New Live
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          label="Search for a Live"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="getLivesList"
          item-key="id"
          fixed-header
          class="elevation-4"
          :page.sync="page"
          @update:page="onPageChange"
          :items-per-page.sync="itemsPerPage"
          @update:items-per-page="onItemsPerPageUpdate"
          :sortBy.sync="sortBy"
          @update:sortBy="onSortByChange"
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-tooltip top color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <img
                  v-if="item.image.link"
                  v-bind="attrs"
                  v-on="on"
                  class="protrain-image-preview"
                  style="cursor: pointer"
                  @click="openDialogPreviewImage(item.image.link)"
                  :src="item.image.link"
                />
              </template>
              <span>Show Image</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.videoLink`]="{ item }">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="primary"
                  @click="openDialogPreviewVideo(item)"
                >
                  <v-icon>mdi-message-video</v-icon>
                </v-btn>
              </template>
              <span>Show Video</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.title.ENG`]="{ item }">
            <div class="nameSlot">{{ item.title.ENG }}</div>
          </template>
          <template v-slot:[`item.description.ENG`]="{ item }">
            <div class="descriptionSlot">{{ item.description.ENG }}</div>
          </template>
          <template v-slot:[`item.dynamicLink`]="{ item }">
            <v-btn icon color="primary" @click="openDialogDynamicLink(item)">
              <v-icon>mdi-link</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <v-switch
              v-if="item"
              color="primary"
              class="center-switch"
              :input-value="item.enabled"
              @click="updateValue(item, 'enabled', !item.enabled)"
              flat
            ></v-switch>
          </template>
          <template v-slot:[`item.isFree`]="{ item }">
            <v-switch
              v-if="item"
              color="primary"
              class="center-switch"
              :input-value="item.isFree"
              @click="updateValue(item, 'isFree', !item.isFree)"
              flat
            ></v-switch>
          </template>
          <template v-slot:[`item.isGrandFathered`]="{ item }">
            <v-switch
              v-if="item"
              color="primary"
              class="center-switch"
              :input-value="item.isGrandFathered"
              @click="
                updateValue(item, 'isGrandFathered', !item.isGrandFathered)
              "
              flat
            ></v-switch>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <options-button
              :onEdit="
                () => {
                  changeRouteLive(item);
                }
              "
              :onDuplicate="undefined"
              :onDelete="
                () => {
                  openDialogConfirm(item);
                }
              "
              :itemId="item.id"
              :key="item.id"
            />
            <!-- <div class="dropdown">
              <span>...</span>
              <div class="dropdown-content">
                <v-btn
                  class="optionButton"
                  color="transparent"
                  @click="changeRouteLive(item)"
                >
                  <v-icon class="mr-2">mdi-pencil</v-icon>Edit
                </v-btn>
                <v-btn
                  class="optionButton"
                  color="transparent"
                  @click="openDialogConfirm(item)"
                >
                  <v-icon class="mr-2">mdi-delete</v-icon>Delete
                </v-btn>
              </div>
            </div> -->
            <!-- <v-btn icon color="primary" @click="changeRouteLive(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              color="red"
              class="mr-3"
              @click="openDialogConfirm(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn> -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
      <v-card class="text-center">
        <v-icon class="text-center ma-2" size="120" color="primary"
          >mdi-alert</v-icon
        >
        <v-card-text class="pa-4 text-center"
          >This action is irreversible and will delete all data about this Live.
          Including the image, data, references and information</v-card-text
        >
        <v-card-text class="text-center">Are you sure?</v-card-text>

        <v-btn
          color="third"
          tile
          width="150"
          class="pa-0 text--secondary"
          @click="closeDialogConfirm()"
          >No</v-btn
        >

        <v-btn
          color="primary"
          tile
          width="150"
          class="pa-0"
          @click="openDialogReAuth('deleteLive', dialog.item)"
          >Yes</v-btn
        >
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>
    <modal-reauth
      :dialogReAuth="dialogReAuth"
      @isSuccessReAuth="isSuccessReAuth"
    ></modal-reauth>
    <v-snackbar
      v-model="snackbar.snackbar"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      >{{ snackbar.text }}</v-snackbar
    >
    <v-dialog
      v-model="dialogPreviewVideo.show"
      transition="dialog-bottom-transition"
      max-width="700"
    >
      <v-card class="text-center">
        <v-card-actions class="pa-0">
          <v-btn
            icon
            color="secondary"
            class="ml-3 mt-2"
            @click="closeDialogVideoPreview()"
          >
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <img
          src="@/assets/logo_x.png"
          style="vertical-align: middle"
          class="mx-2 d-inline"
          width="20"
        />
        <p class="font-weight-bold secondary--text d-inline">
          {{ dialogPreviewVideo.name }}
        </p>
        <v-card-text class="pb-8 mt-3">
          <video
            v-if="dialogPreviewVideo.video"
            ref="listProTrainVideoPreview"
            width="100%"
            height="350px"
            controls
            class="mx-auto"
            :src="dialogPreviewVideo.video ? dialogPreviewVideo.video : ''"
          ></video>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPreviewImage.show" max-width="800">
      <v-card style="line-height: 0; !important;">
        <v-btn
          fab
          x-small
          color="red"
          style="position: absolute; top: 5px; left: 5px"
          @click="closeDialogImagePreview()"
        >
          <v-icon size="15" color="white">mdi-close</v-icon>
        </v-btn>
        <img :src="dialogPreviewImage.img" style="width: 100%; height: 100%" />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDynamicLink.show"
      transition="dialog-bottom-transition"
      max-width="700"
    >
      <v-card class="text-center">
        <v-card-actions class="pa-0">
          <v-btn
            icon
            color="secondary"
            class="ml-3 mt-2"
            @click="closeDialogDynamicLink()"
          >
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="pb-8 mt-3">
          <div class="text-center d-flex align-center mt-5">
            <template
              v-if="
                dialogDynamicLink.item &&
                dialogDynamicLink.item.dynamicLink &&
                dialogDynamicLink.item.dynamicLink.length > 0
              "
            >
              <v-text-field
                v-model="dialogDynamicLink.item.dynamicLink"
                ref="proTrainDynamicLinkRef"
                hide-details="auto"
                maxlength="4"
                class="centered-input"
                readonly
                solo
                flat
                background-color="#efefef"
              ></v-text-field>
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="primary"
                    @click="copyLink(dialogDynamicLink.item.dynamicLink)"
                  >
                    <v-icon>mdi-plus-box-multiple</v-icon>
                  </v-btn>
                </template>
                <span>Copy to clipboard</span>
              </v-tooltip>
            </template>
            <template v-else>
              <p class="secondary--text subtitle-1 mx-auto">
                There is no dynamic link for this workout yet
              </p>
            </template>
          </div>
          <div class="text-center mb-3">
            <v-btn
              class="mt-3 text-center"
              text
              color="secondary"
              :disabled="dialogDynamicLink.loading"
              :loading="dialogDynamicLink.loading"
              @click="generateDynamicLink()"
            >
              <v-icon left dark color="primary"> mdi-link-plus </v-icon>
              Generate Dynamic Link
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
import OptionsButton from "@/components/admin/OptionsButton.vue";
export default {
  name: "LivesList",
  components: { modalReauth, OptionsButton },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: "", align: "center", value: "image", sortable: false },
      { text: "Preview", align: "center", value: "videoLink", sortable: false },
      { text: "Name", align: "center", value: "title.ENG", sortable: true },
      {
        text: "Description",
        align: "center",
        value: "description.ENG",
        sortable: true,
      },
      { text: "Link", align: "center", value: "dynamicLink", sortable: false },
      { text: "Enabled", align: "center", value: "enabled", sortable: true },
      {
        text: "Free",
        align: "center",
        value: "isFree",
        sortable: true,
      },
      {
        text: "Grandfathered",
        align: "center",
        value: "isGrandFathered",
        sortable: true,
      },
      { text: "Action", align: "center", value: "action", sortable: false },
    ],
    dialogReAuth: {
      show: false,
      action: "",
      item: {},
    },
    dialog: {
      show: false,
      item: {},
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    page: 0,
    itemsPerPage: 10,
    sortBy: "name.ENG",
    dialogPreviewVideo: {
      show: false,
      video: "",
      name: "",
    },
    dialogPreviewImage: {
      show: false,
      video: "",
    },
    dialogDynamicLink: {
      show: false,
      loading: false,
      item: {},
    },
  }),
  created() {
    let vm = this;
    vm.getAllLives();
    if (this.$route.params.previousRoute === "Lives Edit") {
      const tableState = localStorage.getItem("livesTableState");
      if (tableState) {
        const tableStateObj = JSON.parse(tableState);
        this.search = tableStateObj.query;
        this.itemsPerPage = tableStateObj.hitsPerPage;
        this.page = tableStateObj.page;
      }
    } else {
      this.itemsPerPage = -1;
    }
  },
  methods: {
    openDialogPreviewVideo(item) {
      const vm = this;
      vm.dialogPreviewVideo = {
        show: true,
        video: item.videoLink ? item.videoLink : "",
        name: item.title.ENG,
      };
    },
    closeDialogVideoPreview() {
      const vm = this;
      vm.dialogPreviewVideo = {
        show: false,
        video: "",
        name: "",
      };
    },
    openDialogPreviewImage(img) {
      const vm = this;
      vm.dialogPreviewImage = {
        show: true,
        img: img ? img : "",
      };
    },
    closeDialogImagePreview() {
      const vm = this;
      vm.dialogPreviewImage.show = false;
      // vm.dialogPreviewImage = {
      //   show: false,
      //   img: ''
      // }
    },
    openDialogDynamicLink(item) {
      const vm = this;
      vm.dialogDynamicLink = {
        show: true,
        loading: false,
        item,
      };
    },
    closeDialogDynamicLink() {
      const vm = this;
      vm.dialogDynamicLink = {
        show: false,
        loading: false,
        item: {},
      };
    },
    generateDynamicLink() {
      const vm = this;
      vm.dialogDynamicLink.loading = true;

      const payload = {
        routeName: "lives",
        title: vm.dialogDynamicLink.item.title.ENG,
        id: vm.dialogDynamicLink.item.id,
        img: vm.dialogDynamicLink.item.image.link,
      };
      vm.$store
        .dispatch("GenerateDynamicLink", payload)
        .then((dynamicLinks) => {
          console.log("DYNAMIC LINKS: ", dynamicLinks);

          vm.dialogDynamicLink.loading = false;
          vm.dialogDynamicLink.item.dynamicLink = dynamicLinks.shortLink;

          vm.updateValue(
            vm.dialogDynamicLink.item,
            "dynamicLink",
            dynamicLinks.shortLink
          )
            .then((live) => {
              // vm.closeDialogDynamicLink()
            })
            .catch((errUpdate) => {
              vm.dialogDynamicLink.loading = false;
              console.error(errUpdate);
            });
        })
        .catch((err) => {
          vm.dialogDynamicLink.loading = false;
          console.error(err);
        });
    },
    copyLink(link) {
      const vm = this;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          vm.snackbar.text = "Copied !";
          vm.snackbar.color = "dark";
          vm.snackbar.snackbar = true;
        })
        .catch((err) => {
          vm.snackbar.text = "Error coping link !";
          vm.snackbar.color = "red";
          vm.snackbar.snackbar = true;
        });
    },
    async getAllLives() {
      const vm = this;
      try {
        vm.loading = true;
        await vm.$store.dispatch("GetLivesList");
        vm.loading = false;
      } catch (error) {
        vm.loading = false;
        console.log(error);
      }
    },
    parseToRelativeDate(date) {
      return moment(date.toDate()).fromNow();
    },
    openDialogConfirm(item) {
      const vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
    },
    closeDialogConfirm() {
      const vm = this;
      vm.dialog.show = false;
      vm.dialog.item = "";
    },
    openDialogReAuth(action, item) {
      let vm = this;
      vm.dialogReAuth.show = true;
      vm.dialogReAuth.action = action;
      vm.dialogReAuth.item = item;
      vm.closeDialogConfirm();
    },
    isSuccessReAuth(dialogReAuthResponse) {
      const vm = this;
      switch (dialogReAuthResponse.action) {
        case "deleteLive":
          vm.deleteLive(dialogReAuthResponse.item);
          break;
        default:
          break;
      }
    },
    async deleteLive(live) {
      const vm = this;
      try {
        vm.$emit("showOverlay", true);
        await vm.$store.dispatch("DeleteLive", live);
        await vm.$store.dispatch("GenerateLog", {
          id: live.id,
          name: live.title["ENG"],
          type: "deletedLive",
        });
        vm.getAllLives();
        vm.$emit("showOverlay", false);
      } catch (error) {
        vm.$emit("showOverlay", false);
        console.error(error);
        let emitData = {
          snackbar: true,
          btn: "",
          y: "bottom",
          x: null,
          mode: "",
          timeout: 2000,
          text: "Error deleting Live",
          color: "red",
        };
        vm.$emit("showSnackBar", emitData);
      }
    },
    changeRouteLive(live) {
      let vm = this;
      vm.$router.push({ name: "Lives Edit", params: live }).catch((err) => {
        vm.snackbar.text =
          "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
    changeRouteFilters() {
      let vm = this;
      vm.$router.push({ name: "Lives Filters" }).catch((err) => {
        vm.snackbar.text =
          "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
    createLive() {
      let vm = this;
      vm.$router.push({ name: "Lives Create" }).catch((err) => {
        vm.snackbar.text =
          "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
    async updateValue(live, key, value) {
      const vm = this;
      const id = await vm.$store.dispatch("UpdateLiveValue", {
        id: live.id,
        data: { [key]: value },
      });
      await vm.$store.dispatch("GenerateLog", {
        id: live.id,
        title: live.title["ENG"],
        type: "editedLive",
      });

      let emitData = {
        snackbar: true,
        btn: "",
        y: "bottom",
        x: null,
        mode: "",
        timeout: 2000,
        text: "Live edited successfully",
        color: "success",
      };
      vm.$emit("showSnackBar", emitData);
      vm.getAllLives();
    },
    onPageChange(page) {
      console.log(page);
    },
    onItemsPerPageUpdate(items) {
      console.log(items);
    },
    onSortByChange(sortBy, test) {
      console.log(sortBy, test);
    },
    // categoriesLives() {
    //   let vm = this;
    //   vm.$router.push({ name: "Lives Categories" }).catch((err) => {
    //     vm.snackbar.text ="Sorry, you don't have permissions to enter this view.";
    //     vm.snackbar.color = "red darken-2";
    //     vm.snackbar.snackbar = true;
    //   });
    // },
  },
  computed: {
    ...mapGetters(["getLivesList"]),
  },
  beforeRouteLeave(to, from, next) {
    console.log(to);
    if (to.name === "Lives Edit") {
      const currentState = JSON.stringify({
        hitsPerPage: this.itemsPerPage,
        query: this.search,
        page: this.page,
      });
      localStorage.setItem("livesTableState", currentState);
    } else {
      localStorage.removeItem("livesTableState");
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "Lives Edit") {
      to.params.previousRoute = from.name;
    }
    next();
  },
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}

.protrain-image-preview {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  vertical-align: middle;
}
.descriptionSlot {
  max-width: 20vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.nameSlot {
  max-width: 20vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>