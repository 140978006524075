<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="2">
        <p class="text-h6 text--secondary font-weight-bold">Firmware Versions</p>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-btn text color="secondary" @click="createFirmwareVersion()">
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          New Firmware Version
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          label="Search for a Firmware Version"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="getFirmwareVersionsList"
          item-key="id"
          fixed-header
          class="elevation-4"
        >
         <template v-slot:[`item.enabled`]="{ item }">
             <v-switch
              v-if="item"
              class="center-switch"
              color="primary"
             :input-value="item.enabled"
              readonly
              flat
            ></v-switch> 
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <options-button
              :onEdit="
                () => {
                  changeRouteFirmwareVersion(item);
                }
              "
              :onDuplicate="undefined"
              :onDelete="
                () => {
                  openDialogConfirm(item);
                }
              "
              :itemId="item.id"
              :key="item.id"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
        <v-card class="text-center">
            <v-icon class="text-center ma-2" size="120" color="primary"
            >mdi-alert</v-icon>
            <v-card-text class="pa-4 text-center"
            >This action is irreversible and will delete all data about this
            Firmware Version. Including the data, file, references and information</v-card-text>
            <v-card-text class="text-center">Are you sure?</v-card-text>

            <v-btn
            color="third"
            tile
            width="150"
            class="pa-0 text--secondary"
            @click="closeDialogConfirm()"
            >No</v-btn
            >

            <v-btn
            color="primary"
            tile
            width="150"
            class="pa-0"
            @click="openDialogReAuth('deleteFirmwareVersion', dialog.item)"
            >Yes</v-btn
            >
            <!-- </v-card-actions> -->
        </v-card>
    </v-dialog>
    <modal-reauth
      :dialogReAuth="dialogReAuth"
      @isSuccessReAuth="isSuccessReAuth"
    ></modal-reauth>
     <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
import OptionsButton from "@/components/admin/OptionsButton.vue";
export default {
  name: "FirmwareVersionList",
  components: { modalReauth,OptionsButton },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: '', align:'center', value:'image', sortable:false },
      { text: 'Title', align:'center', value:'title.ENG', sortable:true },
      { text: 'Version', align:'center', value:'version', sortable:true },
      { text: 'Link', align:'center', value:'link', sortable:true },
      { text: 'Enabled', align:'center', value:'enabled', sortable:true },
      { text: "Action", align: "center", value: "action", sortable: false },
    ],
    dialogReAuth: {
      show: false,
      action: "",
      item: {},
    },
    dialog: {
      show: false,
      item: {},
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
  }),
  created(){
      let vm = this
      vm.getAllFirmwareVersions()
  },
  methods: {
    async getAllFirmwareVersions(){
     const vm = this 
      try {
          vm.loading = true
          await vm.$store.dispatch("GetFirmwareVersionsList")
          vm.loading = false
      } catch (error) {
          vm.loading = false
          console.log(error);
      }
    },
    parseToRelativeDate(date){
     return moment(date.toDate()).fromNow()
    },
    openDialogConfirm(item) {
      const vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
    },
    closeDialogConfirm() {
      const vm = this;
      vm.dialog.show = false;
      vm.dialog.item = "";
    },
    openDialogReAuth(action, item) {
      let vm = this;
      vm.dialogReAuth.show = true;
      vm.dialogReAuth.action = action;
      vm.dialogReAuth.item = item;
      vm.closeDialogConfirm();
    },
    isSuccessReAuth(dialogReAuthResponse) {
      const vm = this;
      switch (dialogReAuthResponse.action) {
        case "deleteFirmwareVersion":
          vm.deleteFirmwareVersion(dialogReAuthResponse.item);
          break;
        default:
          break;
      }
    },
     async deleteFirmwareVersion(firmwareVersion){
      const vm = this;
      try {
        vm.$emit("showOverlay", true);
        await vm.$store.dispatch("DeleteFirmwareVersion", firmwareVersion)
        await vm.$store.dispatch("GenerateLog", {
                id: firmwareVersion.id,
                title: firmwareVersion.title['ENG'],
                type: "deletedFirmwareVersion",
              });
        vm.getAllFirmwareVersions()
        vm.$emit("showOverlay", false);
        
      } catch (error) {
        vm.$emit("showOverlay", false);
          console.error(error);
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error deleting Firmware Version",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
      } 
    },
    changeRouteFirmwareVersion(firmwareVersion){
        let vm = this;
        vm.$router.push({ name: "Firmware Version Edit", params: firmwareVersion })
        .catch((err) => {
            vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
            vm.snackbar.color = "red darken-2";
            vm.snackbar.snackbar = true;
        })
    },
    createFirmwareVersion() {
      let vm = this;
      vm.$router.push({ name: "Firmware Version Create" }).catch((err) => {
        vm.snackbar.text ="Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
  },
  computed:{
      ...mapGetters(["getFirmwareVersionsList"])
  }
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.protrain-image-preview{
    width: 50px; 
    height: 50px; 
    border-radius: 50%; 
    object-fit: cover; 
    vertical-align: middle;
}
</style>